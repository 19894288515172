import { useAppDispatch } from '@/_helpers/store';
import { cancelScreeningEvent } from '@/_reducers/screening';
import { Button, LoadingButton, useToast } from '@/components/ui';
import { cn, extractErrorMsg } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as hompagteStrings } from '@/strings';
import { useState } from 'react';

import { FooterProps } from './types';

const strings = hompagteStrings.screeningEvent.cancelDialg;

function CancelScreeningDialogFooter({ reason, id, handleClose }: FooterProps) {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const submit = async () => {
        trackEvent(MixpanelEvent.IntroCallCancellationSubmit);
        setIsLoading(true);
        await dispatch(
            cancelScreeningEvent({
                id,
                reason,
            }),
        ).catch((error) => {
            toast({
                title: strings.failureToast.failureTitle,
                description: extractErrorMsg(error),
                variant: 'destructive',
            });
        });
        setIsLoading(false);
    };

    return (
        <div className={cn('w-full flex flex-wrap gap-6')}>
            <div className={cn('relative basis-1/2 flex-grow')}>
                <LoadingButton
                    className={cn('w-full')}
                    size={'lg'}
                    variant={'danger'}
                    onClick={submit}
                >
                    {strings.submit}
                </LoadingButton>
            </div>
            <div className={cn('relative basis-1/2 flex-grow')}>
                <Button
                    className={cn('w-full')}
                    size={'lg'}
                    variant={'secondary'}
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    {strings.close}
                </Button>
            </div>
        </div>
    );
}

export default CancelScreeningDialogFooter;
