import { CodeInput } from '@/components/ui';
import {
    INTERNATIONAL_PREFIX,
    cn,
    extractErrorMsg,
    formatInternationalPhoneNumber,
    getFirebaseSendPhoneCodeErrorMessage,
    getFirebaseVerifyCodeErrorMessage,
} from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import ResendCodeButton from '@/phoneSignIn/ResendCodeButton';
import { VERIFICATION_CODE_LENGTH } from '@/phoneSignIn/consts';
import { registration as strings } from '@/strings';
import { useCallback, useEffect, useState } from 'react';

import { useRegisterFormContext } from '../../RegisterContext';
import RegistrationStepLayout from '../../RegistrationStepLayout';
import StepTitle from '../../StepTitle';
import useReRegisterCandidate from './useReRegisterCandidate';

function CodeVerificationForm({
    phone,
    submit,
    resendCode,
}: {
    phone: string;
    submit: (code: string) => Promise<void>;
    resendCode: () => Promise<void>;
}) {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const { setCustomGoNextAction, defaultGoNext, preAuthUser, setPreAuthUser } =
        useRegisterFormContext();
    const isReregistration = preAuthUser?.isReregistration ?? false;
    const reregister = useReRegisterCandidate();

    const clearError = () => setError('');

    const submitCode = useCallback(async () => {
        trackEvent(MixpanelEvent.RegistrationSubmitVerificationCode);
        clearError();
        await submit(code)
            .then(async () => {
                defaultGoNext();
                if (isReregistration) {
                    await reregister();
                }
                setPreAuthUser(null);
            })
            .catch((error) => {
                const msg = getFirebaseVerifyCodeErrorMessage(error);
                setError(msg);
            });
    }, [code, submit, defaultGoNext, isReregistration, reregister, setPreAuthUser]);

    const onResendCode = async (callback: () => void) => {
        trackEvent(MixpanelEvent.RegistrationClickResendCode);
        clearError();
        return await resendCode()
            .then(() => {
                setCode('');
                callback();
            })
            .catch((error) => {
                let msg = getFirebaseSendPhoneCodeErrorMessage(error);
                msg = msg ?? extractErrorMsg(error);
                setError(msg);
            });
    };

    useEffect(() => {
        setCustomGoNextAction(submitCode);
        return () => {
            setCustomGoNextAction(null);
        };
    }, [submitCode, setCustomGoNextAction]);

    const canGoNext = code.length === VERIFICATION_CODE_LENGTH;

    return (
        <RegistrationStepLayout
            canGoNext={canGoNext}
            additionalButtons={<ResendCodeButton resendCode={onResendCode} />}
        >
            <div className={cn('flex flex-col items-center gap-8')}>
                <div className={cn('max-w-full w-[350px]')}>
                    <StepTitle
                        title={strings.verificationCode.title}
                        subtitle={strings.verificationCode.subtitle.func(
                            formatInternationalPhoneNumber({
                                phone,
                                prefix: INTERNATIONAL_PREFIX,
                            }),
                        )}
                    />
                </div>
                <div className={cn('max-w-full w-[350px]')}>
                    <CodeInput
                        value={code}
                        setValue={setCode}
                        error={error}
                        clearError={clearError}
                        onSubmit={submitCode}
                        shouldAutoFocus
                    />
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default CodeVerificationForm;
