import { RootState } from '@/_helpers/store';
import apiClient from '@/lib/api';
import { AppStateModel, FetchModelStatus } from '@/lib/types';
import { extractErrorMsg, generateEmptyFetcherState } from '@/lib/utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppStateSliceState } from './types';

export const fetchCandidateAppStateThunk = createAsyncThunk<
    AppStateModel,
    { candidateId: string },
    { state: RootState }
>('appState/fetchState', async ({ candidateId }, { rejectWithValue }) => {
    try {
        const response = await apiClient.GET(
            '/api/v1/candidate-app-state/{candidate_id}',
            {
                params: { path: { candidate_id: candidateId } },
            },
        );
        return response.data!;
    } catch (error) {
        return rejectWithValue(extractErrorMsg(error));
    }
});

const initialState = {
    data: {},
    fetcher: generateEmptyFetcherState(),
} as AppStateSliceState;

const processesSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<AppStateModel>) => {
            state.data = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCandidateAppStateThunk.pending, (state) => {
                state.fetcher.status = FetchModelStatus.LOADING;
            })
            .addCase(fetchCandidateAppStateThunk.fulfilled, (state, action) => {
                state.fetcher.status = FetchModelStatus.SUCCESS;
                state.fetcher.initiated = true;
                state.data = action.payload;
            })
            .addCase(fetchCandidateAppStateThunk.rejected, (state, action) => {
                state.fetcher.status = FetchModelStatus.FAILURE;
                state.fetcher.error = action.error.message;
            });
    },
});

export default processesSlice;
