import {
    Button,
    Dialog,
    DialogContentWithFooter,
    DialogHeaderAnchor,
    useToast,
} from '@/components/ui';
import { EventRelatedProcess } from '@/lib/types';
import { cn, extractErrorMsg } from '@/lib/utils';
import { dialogs as dialogStrings } from '@/strings';
import { useEffect, useState } from 'react';

import SuccessContent from '../common/SuccessContent';
import DialogTitleHeader from '../common/header';
import { BaseDialogPropsCommon } from '../types';
import CancelationForm from './CancelationForm';
import { getMixpanelEventTrackers } from './mixpanelEvents';

const strings = dialogStrings.cancelInterview;

interface Props extends BaseDialogPropsCommon {
    process: EventRelatedProcess;
    cancelEvent: (reason?: string) => Promise<void>;
    goToTermination: () => void;
}

enum DialogStep {
    Init = 'init',
    Cancel = 'cancelation',
    Success = 'success',
}

function CancelInterviewDialog({
    process,
    isOpen,
    setOpen,
    cancelEvent,
    goToTermination,
}: Props) {
    const { toast } = useToast();
    const [step, setStep] = useState(DialogStep.Init);

    const { trackGoToCancelationForm, trackGoToTermination, trackSubmitCancelation } =
        getMixpanelEventTrackers(process);

    const submitCancel = async (reason?: string) => {
        trackSubmitCancelation(reason);
        return await cancelEvent(reason)
            .then(() => setStep(DialogStep.Success))
            .catch((error) => {
                toast({
                    title: strings.cancelation.submit.failureTitle,
                    description: extractErrorMsg(error),
                    variant: 'destructive',
                });
            });
    };

    useEffect(() => {
        return () => {
            if (!isOpen) {
                setStep(DialogStep.Init);
            }
        };
    }, [isOpen]);

    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            customHeader={
                <>
                    {step === DialogStep.Init && (
                        <DialogTitleHeader
                            title={strings.header.title}
                            subtitle={strings.header.subtitle}
                        />
                    )}
                    {step === DialogStep.Cancel && (
                        <DialogTitleHeader
                            title={strings.cancelation.header.title}
                            subtitle={strings.cancelation.header.subtitle}
                            rootProps={{ className: 'border-none' }}
                        />
                    )}
                    {step === DialogStep.Success && <DialogHeaderAnchor />}
                </>
            }
        >
            <>
                {step === DialogStep.Init && (
                    <DialogContentWithFooter>
                        <div className={cn('w-full flex flex-col items-center gap-6')}>
                            <Button
                                className={cn('w-full max-w-[400px]')}
                                variant={'default'}
                                size={'md'}
                                onClick={() => {
                                    trackGoToCancelationForm();
                                    setStep(DialogStep.Cancel);
                                }}
                            >
                                {strings.buttons.cancel}
                            </Button>
                            <Button
                                className={cn('w-full max-w-[400px]')}
                                variant={'secondary'}
                                size={'md'}
                                onClick={() => {
                                    trackGoToTermination();
                                    goToTermination();
                                }}
                            >
                                {strings.buttons.terminate}
                            </Button>
                        </div>
                    </DialogContentWithFooter>
                )}
                {step === DialogStep.Cancel && (
                    <CancelationForm cancelEvent={submitCancel} />
                )}
                {step === DialogStep.Success && (
                    <SuccessContent
                        title={strings.cancelation.successTitle}
                        subtitle={strings.cancelation.successSubtitle}
                    />
                )}
            </>
        </Dialog>
    );
}

export default CancelInterviewDialog;
