import Logger from '@/_helpers/logger';

const getLocalStorageGracefully = (): Storage | null => {
    if (typeof window === 'undefined') {
        return null;
    }
    const TEST_LOCAL_STORAGE_ITEM = 'carefam_local_storage_test';
    try {
        localStorage.setItem(TEST_LOCAL_STORAGE_ITEM, '1');
        localStorage.removeItem(TEST_LOCAL_STORAGE_ITEM);
        return localStorage;
    } catch (e) {
        Logger.warn(`Can't access localStorage: ${e}`);
        return null;
    }
};

export const localStorageGraceful = getLocalStorageGracefully();

const getSessionStorageGracefully = (): Storage | null => {
    if (typeof window === 'undefined') {
        return null;
    }
    const TEST_SESSION_STORAGE_ITEM = 'carefam_session_storage_test';
    try {
        sessionStorage.setItem(TEST_SESSION_STORAGE_ITEM, '1');
        sessionStorage.removeItem(TEST_SESSION_STORAGE_ITEM);
        return sessionStorage;
    } catch (e) {
        Logger.warn(`Can't access sessionStorage: ${e}`);
        return null;
    }
};

export const sessionStorageGraceful = getSessionStorageGracefully();
