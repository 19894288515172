import { AppDispatch } from '@/_helpers/store';
import { setImpersonatedUser } from '@/_reducers/auth';
import { LogoutIcon } from '@/components/svgs';
import { Button, Dialog, TextInput, useDialogContext, useToast } from '@/components/ui';
import { signOutFirebase } from '@/firebase';
import apiClient from '@/lib/api';
import { Candidate, UserRole } from '@/lib/types';
import { cn, extractErrorMsg } from '@/lib/utils';
import { VenetianMaskIcon } from 'lucide-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

function ImpersonationDialog({ open }: { open: boolean }) {
    const { toast } = useToast();
    const { handleClose } = useDialogContext();
    const [idInputVal, setIdInputVal] = useState('');
    const dispatch = useDispatch<AppDispatch>();

    const handleImpersonatedUser = (
        selectedCandidate: Candidate | null,
        candidate_id: string,
    ) => {
        if (selectedCandidate) {
            dispatch(
                setImpersonatedUser({
                    id: selectedCandidate.id,
                    phoneNumber: selectedCandidate.phone,
                    name: selectedCandidate.name,
                    role: UserRole.Candidate,
                }),
            );
            handleClose();
        } else {
            toast({
                title: 'Could not find candidate',
                description: `Did not find a candidate with id ${candidate_id}`,
                variant: 'destructive',
            });
        }
    };

    const impersonate = async () => {
        const candidateId = idInputVal.trim();
        await apiClient
            .GET('/api/v1/candidates/{candidate_id}', {
                params: { path: { candidate_id: candidateId } },
            })
            .then((response) => {
                const selectedCandidate = response.data!;
                handleImpersonatedUser(selectedCandidate, candidateId);
            })
            .catch((error) => {
                toast({
                    title: 'Failed to impersonate',
                    description: extractErrorMsg(error),
                    variant: 'destructive',
                });
            });
    };

    return (
        <Dialog
            open={open}
            customHeader={
                <div className={cn('p-10 flex items-center')}>
                    <VenetianMaskIcon className={cn('w-16 h-16')} />
                    <h2 className={cn('ml-6 font-bold text-2xl')}>
                        Impersonate A Candidate
                    </h2>
                </div>
            }
        >
            <div className={cn('p-12 pt-4')}>
                <div>
                    <p className={cn('text-black text-13 font-semibold')}>
                        Impersonated Candidate:
                    </p>
                    <TextInput
                        value={idInputVal}
                        setValue={setIdInputVal}
                        placeholder={'Enter candidate id'}
                        className={cn('mt-4')}
                    />
                </div>
                <div
                    className={cn(
                        'w-full mt-20 flex gap-2 justify-between items-center',
                    )}
                >
                    <Button onClick={impersonate} disabled={idInputVal === ''}>
                        Impersonate!
                    </Button>
                    <Button
                        className={cn('flex items-center gap-2')}
                        variant={'secondary'}
                        size={'tiny'}
                        onClick={signOutFirebase}
                    >
                        <LogoutIcon />
                        <div>Sign Out</div>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default ImpersonationDialog;
