import { DateIsoString, isoDatetoLocalDayString, toISOString } from '@/lib/base';
import { AvailabilityWindow } from '@/lib/types';
import { addMinutes } from 'date-fns';
import { flatten } from 'lodash-es';

import { DayInCalendar, EventSlotsByDay, ProcessEventSlot, WeekDates } from './types';

export function getEventSlotsByDay(slots: ProcessEventSlot[]): EventSlotsByDay {
    const slotsByDay: EventSlotsByDay = {};

    slots.forEach((slot) => {
        const dayString = isoDatetoLocalDayString(slot.start);

        if (!slotsByDay[dayString]) {
            slotsByDay[dayString] = [];
        }

        slotsByDay[dayString].push(slot);
    });

    return slotsByDay;
}

function splitIntoWeeks(daysInMonth: DayInCalendar[]): WeekDates[] {
    const result: WeekDates[] = [];

    for (let i = 0; i < daysInMonth.length; i += 7) {
        const week = daysInMonth.slice(i, i + 7) as WeekDates;
        result.push(week);
    }

    return result;
}

export function getMonthWeeks({
    month,
    year,
}: {
    month: number;
    year: number;
}): WeekDates[] {
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    const dayOfWeekFirst = firstDayOfMonth.getDay();
    const daysSinceMonday = dayOfWeekFirst === 0 ? 6 : dayOfWeekFirst - 1;

    const dayOfWeekLast = lastDayOfMonth.getDay();
    const daysToSunday = dayOfWeekLast === 0 ? 0 : 7 - dayOfWeekLast;

    return splitIntoWeeks([
        ...[...Array(daysSinceMonday)].map(() => null),
        ...[
            ...[...Array(lastDayOfMonth.getDate())].map((_, i) => {
                return Number(i + 1);
            }),
        ],
        ...[...Array(daysToSunday)].map(() => null),
    ]);
}

export const getMonthAndYear = (dateInput: DateIsoString) => {
    const date = new Date(dateInput);
    const month = date.getMonth();
    const year = date.getFullYear();
    return { month, year };
};

export const yearAndMonthToDate = ({
    year,
    month,
    day = 1,
}: {
    year: number;
    month: number;
    day?: number;
}) => new Date(year, month, day);

export const dateToStartOfMonthIso = (date: Date): DateIsoString => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    return toISOString(firstDayOfMonth);
};

export const getCurrentMonthStart = (): DateIsoString => {
    const now = new Date();
    return dateToStartOfMonthIso(now);
};

export const getEmptyWeek = () => [...Array(7)].map(() => null) as WeekDates;

export function eachTimeSlotOfInterval({
    start,
    end,
    durationMins,
    minutesGap,
}: {
    start: DateIsoString;
    end: DateIsoString;
    durationMins: number;
    minutesGap: number;
}) {
    const blocks = [] as ProcessEventSlot[];
    const startTime = Math.ceil(Number(new Date(start)) / minutesGap) * minutesGap;
    const endTime = new Date(end);
    for (
        let slotStart = new Date(startTime);
        slotStart < endTime;
        slotStart = addMinutes(slotStart, minutesGap)
    ) {
        const slotEnd = addMinutes(slotStart, durationMins);
        if (slotEnd <= endTime)
            blocks.push({ start: toISOString(slotStart), end: toISOString(slotEnd) });
    }
    return blocks;
}

export function getTimeSlots({
    windows,
    durationMins,
    minutesGap,
}: {
    windows: AvailabilityWindow[];
    durationMins: number;
    minutesGap: number;
}): ProcessEventSlot[] {
    return flatten(
        windows.map((w) => eachTimeSlotOfInterval({ ...w, minutesGap, durationMins })),
    );
}
