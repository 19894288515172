import useQueryParamsAndClear from '@/hooks/useQueryParamsAndClear';
import { DialogName, QueryParam } from '@/lib/types';
import { useEffect } from 'react';

import useOpenDialog from '../useOpenDialog';

function useOpenByQueryParam() {
    const { openDialog } = useOpenDialog(DialogName.ProcessDetails);
    const getParamsAndClear = useQueryParamsAndClear([QueryParam.PROCESS_ID]);

    useEffect(() => {
        const { process_id } = getParamsAndClear();
        if (process_id) {
            openDialog({ processId: process_id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
export default useOpenByQueryParam;
