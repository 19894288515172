import { cn } from '@/lib/utils';

import { useScreeningViewContext } from './ScreeningContext';

function ScreeningSectionFooter({ withDelimiter = false }: { withDelimiter?: boolean }) {
    const { hasOngoingProcesses } = useScreeningViewContext();
    return (
        <div className={cn('relative w-full flex flex-col px-8')}>
            <div
                className={cn('w-full h-0', {
                    'border-t border-neutral200': withDelimiter,
                })}
            />
            <div className={cn('h-0', { 'h-2': hasOngoingProcesses })} />
        </div>
    );
}

export default ScreeningSectionFooter;
