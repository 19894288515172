import { useToast } from '@/components/ui';
import FileUploadButton, {
    FileUploadButtonProps,
} from '@/components/ui/fileInputButton';
import { resumeFileExtension } from '@/lib/api/v1';
import { extractErrorMsg } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { workHistory as strings } from '@/strings';
import { useState } from 'react';

import { MAX_SIZE_LIMIT_IN_BYTES, MAX_SIZE_LIMIT_IN_MB } from './consts';
import { uploadResumeFile } from './utils';

function UploadCVButton({
    onFileChange,
    onFileSelect,
    onOpenSelection,
    candidateId,
    cvFile,
    onErrorChange,
    onUploadSuccess,
    ...fileButtonProps
}: Pick<FileUploadButtonProps, 'onLoadingChange' | 'errorElemProps'> & {
    candidateId: string;
    onFileSelect?: (file: File) => void;
    onFileChange: (file: File | null) => void;
    onOpenSelection: () => void;
    cvFile: File | null;
    onUploadSuccess: (triggerUploadedCb: () => Promise<void>) => void;
    onErrorChange?: (error: string) => void;
}) {
    const { toast } = useToast();
    const [error, setError] = useState('');

    const updateError = (newVal: string) => {
        setError(newVal);
        onErrorChange && onErrorChange(newVal);
    };

    return (
        <FileUploadButton
            label={strings.uploadResume.buttonLabel}
            fileExt={resumeFileExtension}
            preSelectedFile={cvFile}
            onOpenSelection={onOpenSelection}
            error={error}
            clearError={() => updateError('')}
            onFileClear={() => {
                onFileChange(null);
            }}
            onFileSelect={async (file) => {
                if (file.size > MAX_SIZE_LIMIT_IN_BYTES) {
                    updateError(
                        strings.uploadResume.errors.aboveSizeLimit.func(
                            MAX_SIZE_LIMIT_IN_MB,
                        ),
                    );
                    trackEvent(MixpanelEvent.ResumeGetFileSizeError);
                    return;
                }
                onFileSelect && onFileSelect(file);
                await uploadResumeFile(candidateId, file)
                    .then((triggerUploadedCb) => {
                        onUploadSuccess(triggerUploadedCb);
                        onFileChange(file);
                    })
                    .catch((error) => {
                        toast({
                            title: strings.uploadResume.failureTitle,
                            description: extractErrorMsg(error),
                            variant: 'destructive',
                        });
                        throw error;
                    });
            }}
            {...fileButtonProps}
        />
    );
}

export default UploadCVButton;
