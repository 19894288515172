import useCandidateId from '@/hooks/useCandidateId';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { registration as strings } from '@/strings';
import UploadCVButton from '@/workHistory/UploadCVButton';
import { useEffect } from 'react';

import { useRegisterFormContext } from '../../RegisterContext';
import RegistrationStepLayout from '../../RegistrationStepLayout';
import StepTitle from '../../StepTitle';

function UploadResumeForm({ goBack }: { goBack: () => void }) {
    const { cvFile, setCvFile, setCustomGoBackAction } = useRegisterFormContext();
    const candidateId = useCandidateId();

    const handleCvFileChange = (file: File | null) => {
        if (file) {
            trackEvent(MixpanelEvent.RegistrationUploadResumeFile, {
                filename: file.name,
            });
        } else {
            trackEvent(MixpanelEvent.RegistrationClearResumeFile);
        }
        setCvFile(file);
    };

    const canGoNext = !!cvFile;

    useEffect(() => {
        setCustomGoBackAction(goBack);
        return () => {
            setCustomGoBackAction(null);
        };
    }, [goBack, setCustomGoBackAction]);

    return (
        <RegistrationStepLayout canGoNext={canGoNext}>
            <div className={cn('w-full max-w-[400px] flex flex-col items-center')}>
                <div className={cn('w-full')}>
                    <StepTitle title={strings.resume.uploadResume.title} />
                    <div className={cn('mt-10')}>
                        <UploadCVButton
                            cvFile={cvFile}
                            candidateId={candidateId}
                            onFileChange={handleCvFileChange}
                            onFileSelect={(file: File) => {
                                trackEvent(MixpanelEvent.RegistrationSelectResumeFile, {
                                    filename: file.name,
                                });
                            }}
                            onOpenSelection={() => {
                                trackEvent(MixpanelEvent.RegistrationClickUploadResume);
                            }}
                            onUploadSuccess={(triggerUploadedCb) => triggerUploadedCb()}
                        />
                    </div>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default UploadResumeForm;
