import { useAppDispatch } from '@/_helpers/store';
import { setDialogOpen } from '@/_reducers/dialogs';
import { CurrentCandidate } from '@/_selectors';
import { IsWorkHistoryProcessDialogOpen } from '@/_selectors/generation_1/dialogs';
import { DialogName } from '@/lib/types';
import { useSelector } from 'react-redux';

import UpdateWorkHistoryDialog from './UpdateWorkHistoryDialog';

function UpdateWorkHistoryDialogProvider() {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(IsWorkHistoryProcessDialogOpen);
    const { id: candidateId } = useSelector(CurrentCandidate);
    const hasData = !!candidateId;

    const setOpen = (open: boolean) => {
        dispatch(setDialogOpen(DialogName.UpdateWorkHistory, open));
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            {hasData && (
                <UpdateWorkHistoryDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    candidateId={candidateId}
                />
            )}
        </>
    );
}

export default UpdateWorkHistoryDialogProvider;
