import type { FacilityType, OrgAdvantage, OrgBenefit } from '@/lib/api/v1';

export const orgBenefitToString: Record<OrgBenefit, string> = {
    sign_on_bonus: 'Sign-on bonus',
    tuition_reimbursment: 'Tuition reimbursment',
    low_premiums: 'Low premiums',
    retirement_plan: 'Retirement plan',
    paid_time_off: 'Paid time off',
    mileage_reimbursement: 'Mileage reimbursement',
    comprehensive_healthcare: 'Comprehensive healthcare',
};

export const orgAdvantageToString: Record<OrgAdvantage, string> = {
    staff_celebrations: 'Staff celebrations',
    comfortable_staffing_ratios: 'Good staff-to-patient ratio',
    career_growth_opportunities: 'Career growth opportunities',
};

export const facilityTypeToString: Record<FacilityType, string> = {
    assisted_living: 'Assisted living',
    long_term_care: 'Long-term care',
    memory_care: 'Memory care',
    skilled_nursing: 'Skilled nursing',
    home_health: 'Home health',
};

export const facilityTypeToDescriptorString: Record<FacilityType, string> = {
    assisted_living: 'facility',
    long_term_care: 'facility',
    memory_care: 'facility',
    skilled_nursing: 'facility',
    home_health: 'agency',
};
