import Logger from '@/_helpers/logger';
import { useAppDispatch } from '@/_helpers/store';
import { updateCandidateAppState } from '@/_reducers/appState';
import { CampaignEventName } from '@/adCampaignTracking';
import useTrackAdsCampaignEvent from '@/adCampaignTracking/useTrackAdsCampaignEvent';
import { extractErrorMsg } from '@/lib/utils';
import { useCallback } from 'react';

export default function useFinishRegistration() {
    const dispatch = useAppDispatch();
    const trackAdsCampaignEvent = useTrackAdsCampaignEvent();
    return useCallback(() => {
        trackAdsCampaignEvent(CampaignEventName.FinishedPostRegistrationForm);
        dispatch(
            updateCandidateAppState({
                finishedRegistration: true,
            }),
        ).catch((error) => {
            Logger.error(extractErrorMsg(error));
        });
    }, [dispatch, trackAdsCampaignEvent]);
}
