import { IsMissingResumeHistory } from '@/_selectors';
import { cn } from '@/lib/utils';
import { useSelector } from 'react-redux';

import CompeteYourProfileBlock from './CompeteYourProfileBlock';
import ProcessesView from './ProcessesView';
import ScreeningSection from './screeningEvent/ScreeningSection';

function ActiveCandidateView() {
    const isMissingResume = useSelector(IsMissingResumeHistory);
    return (
        <div className={cn('w-full h-full flex flex-col items-stretch')}>
            <ScreeningSection />
            <div className={cn('relative flex-grow w-full')}>
                <ProcessesView />
            </div>
            {isMissingResume && (
                <div className={cn('pb-4 -mt-2')}>
                    <CompeteYourProfileBlock />
                </div>
            )}
        </div>
    );
}

export default ActiveCandidateView;
