import { IsLoggedIn } from '@/_selectors';
import Loader from '@/components/lotties/Loader';
import useCandidateAppState from '@/fetchers/useCandidateAppState';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import useProcessModels from '@/fetchers/useProcessesModels';
import { cn } from '@/lib/utils';
import { homepage as strings } from '@/strings';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import AppPageLayout from '../AppPageLayout';
import ActiveCandidateView from './ActiveCandidateView';
import HomepageHeader from './HomepageHeader';
import JustMissingResumeView from './JustMissingResumeView';
import NoProcessesView from './NoProcessesView';
import SearchingPage from './SearchingPage';
import { HomePageStatus } from './types';
import useHomePageStatus from './useHomePageState';

function HomePage() {
    const pageStatus = useHomePageStatus();
    const { refresh: refreshAppState } = useCandidateAppState();
    const { refresh: refreshCurrentCandidate } = useCurrentCandidate();
    const { refresh: refreshProcesses } = useProcessModels();
    const isLoggedIn = useSelector(IsLoggedIn);

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }
        refreshAppState();
        refreshCurrentCandidate();
        refreshProcesses();
    }, [refreshProcesses, refreshAppState, refreshCurrentCandidate, isLoggedIn]);

    return (
        <AppPageLayout header={<HomepageHeader />}>
            <div className={cn('relative h-full w-full')}>
                {pageStatus === HomePageStatus.WaitingData && (
                    <div
                        className={cn(
                            'flex justify-center items-center py-6 h-full w-full',
                        )}
                    >
                        <Loader variant='primary' sizing='lg' delay={700} />
                    </div>
                )}
                {pageStatus === HomePageStatus.SearchingFacilities && <SearchingPage />}
                {pageStatus === HomePageStatus.NoProcesses && (
                    <NoProcessesView
                        title={strings.noProcesses.title}
                        subtitle={strings.noProcesses.subtitle}
                    />
                )}
                {pageStatus === HomePageStatus.NoMoreProcesses && (
                    <NoProcessesView
                        title={strings.noMoreProcesses.title}
                        subtitle={strings.noMoreProcesses.subtitle}
                    />
                )}
                {pageStatus === HomePageStatus.Active && <ActiveCandidateView />}
                {pageStatus === HomePageStatus.JustResume && <JustMissingResumeView />}
            </div>
        </AppPageLayout>
    );
}

export default HomePage;
