import { Button, Dialog, DialogContentWithFooter, PageLoader } from '@/components/ui';
import EmptyDialogHeader from '@/components/ui/dialog/emptyDialogHeader';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { introCallPage as strings } from '@/strings';
import { useEffect, useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

import { CalendlyWidgetProps } from './types';

function CalendlyWidget({ open, setOpen, link, onEventScheduled }: CalendlyWidgetProps) {
    const [scheduledDone, setScheduledDone] = useState(false);

    useCalendlyEventListener({
        onDateAndTimeSelected: () => {
            trackEvent(MixpanelEvent.CalendlyWidgetSelectSlot);
        },
        onEventScheduled: (e) => {
            trackEvent(MixpanelEvent.CalendlyWidgetScheduleMeeting);
            setScheduledDone(true);
            onEventScheduled && onEventScheduled(e);
        },
        onEventTypeViewed: () => {
            trackEvent(MixpanelEvent.CalendlyWidgetViewCalendar);
        },
    });

    const { data: candidate } = useCurrentCandidate();
    const { phone, name, email } = candidate;

    useEffect(() => {
        if (!open) {
            setScheduledDone(false);
        }
    }, [open]);

    return (
        <Dialog
            alwaysExpanded
            open={open}
            setOpen={setOpen}
            customHeader={<EmptyDialogHeader handleClose={() => setOpen(false)} />}
        >
            <DialogContentWithFooter
                bodyProps={{
                    className: cn('p-0 max-h-full h-[100vh] overflow-auto'),
                }}
                footer={
                    scheduledDone ? (
                        <div className={cn('flex w-full justify-center')}>
                            <Button
                                className={cn('max-w-full w-60')}
                                onClick={() => {
                                    trackEvent(MixpanelEvent.CalendlyWidgetClickGotIt);
                                    setOpen(false);
                                }}
                            >
                                {strings.postSchedule.okButton}
                            </Button>
                        </div>
                    ) : null
                }
                footerProps={{ className: cn('py-4') }}
            >
                <InlineWidget
                    url={link}
                    styles={{ height: '101%' }}
                    prefill={{
                        name,
                        email,
                        location: phone,
                    }}
                    pageSettings={{ hideGdprBanner: true }}
                    LoadingSpinner={() => (
                        <PageLoader
                            delay={0}
                            containerProps={{ className: cn('absolute') }}
                            onFinish={() => {
                                trackEvent(MixpanelEvent.CalendlyWidgetIframeLoaded);
                            }}
                        />
                    )}
                />
            </DialogContentWithFooter>
        </Dialog>
    );
}

export default CalendlyWidget;
