import Logger from '@/_helpers/logger';
import { useAppDispatch } from '@/_helpers/store';
import { updateCandidateAppState } from '@/_reducers/appState';
import { PixelEventName, trackFbPixelEventOnce } from '@/facebookPixel';
import { extractErrorMsg } from '@/lib/utils';
import { useCallback } from 'react';

export default function useFinishRegistration() {
    const dispatch = useAppDispatch();
    return useCallback(() => {
        trackFbPixelEventOnce(PixelEventName.FinishedPostRegistrationForm);
        dispatch(
            updateCandidateAppState({
                finishedRegistration: true,
            }),
        ).catch((error) => {
            Logger.error(extractErrorMsg(error));
        });
    }, [dispatch]);
}
