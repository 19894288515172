import { RootState } from '@/_helpers/store';
import { CurrentUserId } from '@/_selectors';
import apiClient from '@/lib/api';
import { getAllEnumValues } from '@/lib/base';
import { FetchModelStatus, ScreeningEvent } from '@/lib/types';
import { extractErrorMsg, generateEmptyFetcherState } from '@/lib/utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { keyBy, mapValues, orderBy } from 'lodash-es';

import { FetcherName, ScreeningSliceState } from './types';

export const fetchScreeningLinkThunk = createAsyncThunk<
    string | null,
    undefined,
    Record<string, never>
>('screening/fetchLink', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.GET('/api/v1/screenings/link');
        return response.data!.link;
    } catch (error) {
        return rejectWithValue(extractErrorMsg(error));
    }
});

export const fetchScreeningEventThunk = createAsyncThunk<
    ScreeningEvent | null,
    undefined,
    { state: RootState }
>('screening/fetchEvent', async (_, { rejectWithValue, getState }) => {
    try {
        const userId = CurrentUserId(getState());
        const response = await apiClient.GET('/api/v1/screenings', {
            params: { query: { candidate_id: userId! } },
        });
        if (!response.data) {
            return null;
        }
        return orderBy(response.data.items, 'createdAt', 'desc')[0] ?? null;
    } catch (error) {
        return rejectWithValue(extractErrorMsg(error));
    }
});

const fetcherNames = getAllEnumValues(FetcherName);

const initialState = {
    data: {},
    fetchers: mapValues(
        keyBy(fetcherNames, (n) => n),
        generateEmptyFetcherState,
    ),
} as ScreeningSliceState;

const screeningSlice = createSlice({
    name: 'screening',
    initialState,
    reducers: {
        updateLink: (state, action: PayloadAction<string>) => {
            state.data.link = action.payload;
        },
        updateScreeningEvent: (state, action: PayloadAction<ScreeningEvent | null>) => {
            state.data.screening = action.payload;
        },
        updateScreeningEventIfCurrent: (
            state,
            action: PayloadAction<ScreeningEvent>,
        ) => {
            if (state.data.screening?.id === action.payload.id) {
                state.data.screening = action.payload;
            }
        },
        reset: () => initialState,
        resetLink: (state) => {
            state.data.link = null;
            state.fetchers.link = generateEmptyFetcherState();
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchScreeningLinkThunk.pending, (state) => {
                state.fetchers.link.status = FetchModelStatus.LOADING;
            })
            .addCase(fetchScreeningLinkThunk.fulfilled, (state, action) => {
                state.fetchers.link.status = FetchModelStatus.SUCCESS;
                state.fetchers.link.initiated = true;
                state.data.link = action.payload;
            })
            .addCase(fetchScreeningLinkThunk.rejected, (state, action) => {
                state.fetchers.link.status = FetchModelStatus.FAILURE;
                state.fetchers.link.error = action.error.message;
            })
            .addCase(fetchScreeningEventThunk.pending, (state) => {
                state.fetchers.screeningEvent.status = FetchModelStatus.LOADING;
            })
            .addCase(fetchScreeningEventThunk.fulfilled, (state, action) => {
                state.fetchers.screeningEvent.status = FetchModelStatus.SUCCESS;
                state.fetchers.screeningEvent.initiated = true;
                state.data.screening = action.payload;
            })
            .addCase(fetchScreeningEventThunk.rejected, (state, action) => {
                state.fetchers.screeningEvent.status = FetchModelStatus.FAILURE;
                state.fetchers.screeningEvent.error = action.error.message;
            });
    },
});

export default screeningSlice;
