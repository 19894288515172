import { FacilityIcon } from '@/components/svgs';
import { Process } from '@/lib/types';
import { cn, getSelectedJobMatch } from '@/lib/utils';

import { getShadowForWhite } from './utils';

interface CardProps {
    process: Process;
}

function ProcessCardHeader({ process }: CardProps) {
    const jm = getSelectedJobMatch(process.jobMatches);
    const facility = jm?.facility;

    const shadow = getShadowForWhite(process.organization?.brandColor ?? '#000');
    return (
        <div
            className={cn('relative w-full flex items-start p-7 gap-6')}
            style={{ background: process.organization.brandColor ?? undefined }}
        >
            <div
                className={cn(
                    'w-14 h-14 rounded-full bg-white overflow-hidden pointer-events-none select-none',
                    'shrink-0',
                    'flex items-center justify-center',
                )}
            >
                <img
                    src={process.organization.logoUrl ?? undefined}
                    alt='icon'
                    className={cn('max-h-12 w-auto')}
                />
            </div>
            <div
                className={cn('realtive flex-grow overflow-hidden')}
                style={{ textShadow: shadow }}
            >
                <div className={cn('max-w-full')}>
                    <div className={cn('flex items-center gap-2')}>
                        <FacilityIcon
                            className={cn('h-4 w-4 stroke-white fill-white stroke-[3]')}
                            style={{ filter: `drop-shadow(${shadow})` }}
                        />
                        <div className={cn('text-white text-12 font-semibold')}>
                            {process.organization?.name ?? ''}
                        </div>
                    </div>
                    <div
                        className={cn(
                            'text-white text-15 font-bold whitespace-nowrap text-ellipsis overflow-hidden',
                        )}
                    >
                        {facility?.name ?? ''}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProcessCardHeader;
