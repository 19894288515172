import Logger from '@/_helpers/logger';
import {
    CODE_EXPIRED,
    FIREBASE_INVALID_CODE_ERROR_CODE,
    INVALID_PHONE_NUMBER,
    NETWORK_REQUEST_FAILED,
    NO_FIREBASE_USER_ERROR_CODE,
} from '@/firebase';
import { authErrors as strings } from '@/strings';

import { extractErrorMsg, extractErrorType } from './errors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function alertFirebaseSignInError(e: any) {
    const type = extractErrorType(e);
    if (
        [
            NO_FIREBASE_USER_ERROR_CODE,
            INVALID_PHONE_NUMBER,
            NETWORK_REQUEST_FAILED,
        ].includes(type)
    ) {
        return;
    }
    Logger.warn(
        `Got unknown in firebase error on phone sign in: ${extractErrorMsg(e)} | data: ${JSON.stringify(e)}`,
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function alertFirebaseCodeVerificationError(e: any) {
    const type = extractErrorType(e);
    if (
        [
            FIREBASE_INVALID_CODE_ERROR_CODE,
            CODE_EXPIRED,
            NETWORK_REQUEST_FAILED,
        ].includes(type)
    ) {
        return;
    }
    Logger.warn(
        `Got unknown in firebase error on code verification: ${extractErrorMsg(e)} | data: ${JSON.stringify(e)}`,
    );
}

export function getFirebaseSendPhoneCodeErrorMessage(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: any,
): string | undefined {
    const type = extractErrorType(e);
    if (type === INVALID_PHONE_NUMBER) {
        return strings.invalidPhone;
    }
    if (type === NETWORK_REQUEST_FAILED) {
        return strings.networkError;
    }
}

export function getFirebaseVerifyCodeErrorMessage(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: any,
): string {
    const type = extractErrorType(e);
    if (type === FIREBASE_INVALID_CODE_ERROR_CODE) {
        return strings.codeVerification.invalidCode;
    }
    if (type === CODE_EXPIRED) {
        return strings.codeVerification.codeExpired;
    }
    if (type === NETWORK_REQUEST_FAILED) {
        return strings.networkError;
    }
    return strings.codeVerification.default;
}
