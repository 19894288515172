import { Button, LoadingButton } from '@/components/ui';
import useScreeningScheduleLink from '@/fetchers/useScreeningScheduleLink';
import useExternalState from '@/hooks/useExternalState';
import { TZ } from '@/lib/types';
import { cn, isWithinTimeframe } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as strings } from '@/strings';

import CalendlyWidget from './CalendlyWidget';
import { CalendlyWidgetProps, CalendlyWidgetType } from './types';

const TARGET_PHONE_NUMBER = '332-999-9337';
const START_WEEK_DAY = 0;
const END_WEEK_DAY = 4;
const START_WORKING_HOUR = 10;
const END_WORKING_HOUR = 17;
const TIMEZONE = TZ.ET;

function ScheduleIntroCallView({
    registerEvent,
    inProgressCandidateMsg,
    customTitle,
    customSubtitle,
    open: openExternal,
    setOpen: setOpenExternal,
}: {
    registerEvent: CalendlyWidgetProps['onEventScheduled'];
    inProgressCandidateMsg: boolean;
    customTitle?: string;
    customSubtitle?: string;
    open?: boolean;
    setOpen?: (open: boolean) => void;
}) {
    const { data: link, isWaiting } = useScreeningScheduleLink();
    const [isOpen, setIsOpen] = useExternalState(openExternal, setOpenExternal, {
        defaultVal: false,
    });

    const isInsideWorkingHours = isWithinTimeframe({
        startWeekDay: START_WEEK_DAY,
        endWeekDay: END_WEEK_DAY,
        startWorkingHour: START_WORKING_HOUR,
        endWorkingHour: END_WORKING_HOUR,
        timezone: TIMEZONE,
    });

    return (
        <div className={cn('flex items-center justify-center py-12')}>
            <div className={cn('relative w-full h-full pt-4 pb-2 mb-auto px-8')}>
                <div className={cn('text-center text-24  font-bold')}>
                    {customTitle ?? strings.scheduleIntroCall.title}
                </div>
                <div className={cn('text-center text-15 font-medium mt-3')}>
                    {customSubtitle ??
                        (inProgressCandidateMsg
                            ? strings.scheduleIntroCall.subtitle.inProgressCandidate
                            : strings.scheduleIntroCall.subtitle.newCandidate)}
                </div>
                <div className={cn('flex w-full justify-center pt-8')}>
                    <div
                        className={cn(
                            'flex flex-row-reverse w-full gap-4 items-center justify-center flex-nowrap',
                        )}
                    >
                        <LoadingButton
                            variant={'default'}
                            size={'lg'}
                            className={cn('text-13 px-4 w-full max-w-60')}
                            loading={isWaiting && isOpen}
                            onClick={() => {
                                trackEvent(MixpanelEvent.IntroCallClickSchedule);
                                setIsOpen(true);
                            }}
                        >
                            {strings.scheduleIntroCall.button}
                        </LoadingButton>
                        {isInsideWorkingHours && (
                            <Button
                                variant={'secondary'}
                                size={'lg'}
                                className={cn('text-13 px-4 w-full max-w-60')}
                                onClick={() => {
                                    trackEvent(MixpanelEvent.IntroCallClickCallUsNow);
                                    window.location.href = `tel:+1${TARGET_PHONE_NUMBER}`;
                                }}
                            >
                                {strings.scheduleIntroCall.callUsNow.button}
                            </Button>
                        )}
                    </div>
                </div>
                {link && isOpen && (
                    <CalendlyWidget
                        open={isOpen}
                        setOpen={setIsOpen}
                        link={link}
                        type={CalendlyWidgetType.Schedule}
                        onEventScheduled={registerEvent}
                    />
                )}
            </div>
        </div>
    );
}

export default ScheduleIntroCallView;
