import { Dialog, DialogHeaderAnchor } from '@/components/ui';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogStrings } from '@/strings';
import { useEffect, useState } from 'react';

import DialogTitleHeader from '../common/header';
import { BaseDialogPropsCommon } from '../types';
import UpdateWorkHistoryDialogContent from './content';
import { DialogStep } from './types';

const strings = dialogStrings.workHistory;

interface Props extends BaseDialogPropsCommon {
    candidateId: string;
}

function UpdateWorkHistoryDialog({ isOpen, setOpen, candidateId }: Props) {
    const [step, setStep] = useState(DialogStep.CV);

    const goToCV = () => {
        trackEvent(MixpanelEvent.ResumeGoBackToUploadStep);
        setStep(DialogStep.CV);
    };

    useEffect(() => {
        return () => {
            if (!isOpen) {
                setStep(DialogStep.CV);
            }
        };
    }, [isOpen]);

    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            customHeader={
                <>
                    {step === DialogStep.CV && (
                        <DialogTitleHeader
                            title={strings.header.title}
                            subtitle={strings.header.subtitle}
                            rootProps={{ className: 'border-none' }}
                        />
                    )}
                    {step === DialogStep.RecentJob && (
                        <DialogTitleHeader
                            title={strings.header.title}
                            subtitle={strings.lastExperience.subtitle}
                            goBack={goToCV}
                        />
                    )}
                    {step === DialogStep.Success && <DialogHeaderAnchor />}
                </>
            }
        >
            <UpdateWorkHistoryDialogContent
                step={step}
                setStep={setStep}
                candidateId={candidateId}
            />
        </Dialog>
    );
}

export default UpdateWorkHistoryDialog;
