import { useCallback, useEffect } from 'react';

import { useRegisterFormContext } from '../RegisterContext';
import useRegisterAction from '../useRegister';
import { canOnlyUpdatePhoneNumber } from '../utils';
import PhoneNumberForm from './registration/PhoneNumberForm';

function PhoneNumberStep() {
    const {
        phoneNumber,
        setPhoneNumber,
        sendPhoneCode,
        defaultGoNext,
        setPreventGoBack,
        preAuthUser,
    } = useRegisterFormContext();
    const register = useRegisterAction();

    const onSendPhoneCode = useCallback(
        async (phone: string) => {
            await sendPhoneCode(phone).then(defaultGoNext);
        },
        [sendPhoneCode, defaultGoNext],
    );

    useEffect(() => {
        const blockGoBack = !!preAuthUser && canOnlyUpdatePhoneNumber(preAuthUser);
        setPreventGoBack(blockGoBack);
        return () => {
            setPreventGoBack(false);
        };
    }, [preAuthUser, setPreventGoBack]);

    return (
        <PhoneNumberForm
            sendPhoneCode={onSendPhoneCode}
            phone={phoneNumber}
            setPhone={setPhoneNumber}
            register={register}
        />
    );
}

export default PhoneNumberStep;
