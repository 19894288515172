const strings = {
    title: {
        func: (firstName: string) =>
            `Welcome ${firstName}! Let's get you started on your job search`,
        example: ['Candy'],
    },
    subtitle:
        "Set up an intro call with Carefam team member to activate your job search. This isn't an interview, so no prep is needed 🙂",
    skipButton: "I'll skip for now",
    postSchedule: {
        okButton: 'Got it!',
    },
};

export default strings;
