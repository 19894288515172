import config from '@/config';
import { deleteApp, getApp, getApps, initializeApp } from 'firebase/app';
import { AuthErrorCodes, getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    projectId: config.FIREBASE_PROJECT_ID,
    messagingSenderId: config.FIREBASE_MSG_SENDER_ID,
    appId: config.FIREBASE_APP_ID,
};

// Initialize Firebase, first reset the app if it already exists
if (getApps().length) {
    deleteApp(getApp());
}
const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);
firebaseAuth.settings.appVerificationDisabledForTesting = config.DEV_FIREBASE_DEBUG_MODE;
// firebaseAuth.languageCode = 'en';

export const signOutFirebase = async () => {
    await firebaseAuth.signOut();
};

export const FIREBASE_INVALID_CODE_ERROR_CODE = AuthErrorCodes.INVALID_CODE;
export const NO_FIREBASE_USER_ERROR_CODE = AuthErrorCodes.ADMIN_ONLY_OPERATION;
export const INVALID_PHONE_NUMBER = AuthErrorCodes.INVALID_PHONE_NUMBER;
export const NETWORK_REQUEST_FAILED = AuthErrorCodes.NETWORK_REQUEST_FAILED;
export const CODE_EXPIRED = AuthErrorCodes.CODE_EXPIRED;
