import Logger from '@/_helpers/logger';
import { calculateBrightness, colorStringToRgb } from '@/lib/utils';

export function getWhiteTextShadowOpacity(backgroundColor: string) {
    const rgb = colorStringToRgb(backgroundColor);

    if (!rgb) {
        Logger.error(`Invalid color format: ${backgroundColor}`);
        return 0;
    }

    const brightness = calculateBrightness(rgb.r, rgb.g, rgb.b);

    const opacity = Math.floor((20 * brightness) / 512) / 20;
    if (opacity < 0.2) {
        return 0;
    }
    return opacity;
}

export function getShadowForWhite(backgroundColor: string) {
    const opacity = getWhiteTextShadowOpacity(backgroundColor);
    return `1px 1px 5px rgba(0, 0, 0, ${opacity})`;
}
