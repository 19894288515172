import Logger from '@/_helpers/logger';
import { AppDispatch, GetRootState } from '@/_helpers/store';
import { IsImpersonating } from '@/_selectors';
import config from '@/config';
import { firebaseAuth } from '@/firebase';
import apiClient from '@/lib/api';
import customHeaders, { CustomHeaderName } from '@/lib/api/customHeaders';
import { CookieNames } from '@/lib/base';
import { AuthUser, IdTokenJwtPayload, UserRole } from '@/lib/types';
import { localStorageGraceful } from '@/lib/utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

function storeAuthTokenCookies(token: string) {
    Cookies.set(CookieNames.authToken, token, {
        expires: config.DEFAULT_TOKEN_TTL_DAYS,
    });
}

export const registerAuthUser = createAsyncThunk(
    'auth/registerAuthUser',
    async (user: User, { dispatch }) => {
        const token = await user.getIdToken();
        storeAuthTokenCookies(token);
        dispatch(storeAuthUser(user));
    },
);

export const fetchUserNameFromDB =
    () => async (dispatch: AppDispatch, getState: GetRootState) => {
        const isImpersonating = IsImpersonating(getState());
        if (isImpersonating) {
            return;
        }
        const response = await apiClient.GET('/api/v1/users/me');
        dispatch(authSlice.actions.updateName(response.data!.name));
    };

export const clearAuthUser = createAsyncThunk('auth/clearAuthUser', async () => {
    Cookies.remove(CookieNames.authToken);
});

export const refreshUserToken = createAsyncThunk('auth/refreshUserToken', async () => {
    if (firebaseAuth.currentUser) {
        try {
            const newToken = await firebaseAuth.currentUser.getIdToken(true);
            storeAuthTokenCookies(newToken);
            return newToken;
        } catch (_e) {
            clearAuthUser();
        }
    } else {
        clearAuthUser();
    }
});

const storeAuthUser = (user: User) => {
    const { phoneNumber, uid } = user;
    const authUser: AuthUser = { phoneNumber: phoneNumber!, id: uid };
    const token = Cookies.get(CookieNames.authToken);
    try {
        const tokenClaims = jwtDecode<IdTokenJwtPayload>(token ?? '');
        const { role = UserRole.Candidate } = tokenClaims ?? {};
        authUser['role'] = role;
    } catch (e) {
        Logger.warn(
            `Failed to get role from id token (has_token: ${!!token}). Error: ${e}`,
        );
    }
    return authSlice.actions.setUser(authUser);
};

export const setImpersonatedUser = (user: AuthUser) => {
    customHeaders[CustomHeaderName.Impersonated] = user.phoneNumber;
    customHeaders[CustomHeaderName.ImpersonatedType] = 'candidate';
    localStorageGraceful?.setItem(
        'oparetorData_impersonatedCandidate',
        JSON.stringify(user),
    );
    return authSlice.actions.setImpersonatedUser(user);
};
export const clearImpersonatedUser = () => {
    delete customHeaders[CustomHeaderName.Impersonated];
    delete customHeaders[CustomHeaderName.ImpersonatedType];
    if (localStorageGraceful) {
        delete localStorageGraceful['oparetorData_impersonatedCandidate'];
    }
    return authSlice.actions.clearImpersonatedUser();
};

interface AuthState {
    user: AuthUser | null;
    impersonatedUser: AuthUser | null;
}

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        impersonatedUser: null,
    } as AuthState,
    reducers: {
        updateName: (state, action: PayloadAction<string>) => {
            if (state.user) {
                state.user.name = action.payload;
            }
        },
        setUser: (state, action: PayloadAction<AuthUser>) => {
            state.user = action.payload;
        },
        setImpersonatedUser: (state, action: PayloadAction<AuthUser>) => {
            state.impersonatedUser = action.payload;
        },
        clearImpersonatedUser: (state) => {
            state.impersonatedUser = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearAuthUser.fulfilled, (state) => {
            state.user = null;
        });
    },
});

export default authSlice;
