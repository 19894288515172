import { BaseTextInput } from '@/components/ui';
import { cn, onEnterHandler } from '@/lib/utils';
import { composeEventHandlers } from '@radix-ui/primitive';

import OtpInput from './base';
import { CodeInputClearErrorsPlan } from './types';

function CodeInput({
    value,
    clearError,
    error,
    setValue,
    clearErrorsPlan = CodeInputClearErrorsPlan.All,
    onSubmit = () => {},
    errorElemProps = {},
    shouldAutoFocus = true,
}: {
    clearError: () => void;
    error: string;
    value: string;
    setValue: (val: string) => void;
    clearErrorsPlan?: CodeInputClearErrorsPlan;
    onSubmit?: () => Promise<void> | void;
    errorElemProps?: React.HTMLAttributes<HTMLParagraphElement>;
    shouldAutoFocus?: boolean;
}) {
    const { onKeyDown: onEnterKeyDown } = onEnterHandler(onSubmit);
    const indicateError = !!error;

    const resetError = () => {
        if (indicateError && clearError) {
            clearError();
        }
    };

    const updateCode = (val: string) => {
        setValue(val);
        resetError();
    };

    return (
        <>
            <OtpInput
                shouldAutoFocus={shouldAutoFocus}
                value={value}
                onChange={updateCode}
                numInputs={6}
                inputType='tel'
                renderSeparator={<div className={cn(' flex-shrink-0 w-2')} />}
                renderInput={({
                    className,
                    placeholder,
                    onFocus,
                    onKeyDown,
                    ...props
                }) => (
                    <BaseTextInput
                        placeholder={'*' ?? placeholder}
                        inputClassName={cn(
                            'focus:placeholder-transparent placeholder:text-20 tr placeholder:translate-y-1.5',
                        )}
                        className={cn(
                            'min-w-[42px] max-w-[55px] flex-grow text-16 font-semibold flex justify-center px-3',
                            { 'border-red200': indicateError },
                            className,
                        )}
                        onClick={
                            clearErrorsPlan === CodeInputClearErrorsPlan.All
                                ? resetError
                                : undefined
                        }
                        onFocus={composeEventHandlers(
                            onFocus,
                            clearErrorsPlan === CodeInputClearErrorsPlan.All
                                ? resetError
                                : undefined,
                        )}
                        onContextMenu={(e) => e.preventDefault()}
                        onKeyDown={composeEventHandlers(onKeyDown, onEnterKeyDown)}
                        {...props}
                    />
                )}
            />
            {indicateError && (
                <p
                    {...errorElemProps}
                    className={cn(
                        'mt-8 font-medium text-red200 text-14',
                        errorElemProps?.className,
                    )}
                >
                    {error}
                </p>
            )}
        </>
    );
}

export default CodeInput;
