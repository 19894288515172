import { FacilityIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';

function NoProcessesView({ title, subtitle }: { title: string; subtitle: string }) {
    return (
        <div className={cn('h-full flex items-center justify-center py-8')}>
            <div
                className={cn(
                    'relative w-full h-full flex flex-col items-center justify-center px-8',
                )}
            >
                <FacilityIcon className={cn('stroke-neutral100 fill-neutral100')} />
                <div className={cn('text-center text-24 font-bold mt-10')}>{title}</div>
                <div className={cn('text-center text-15 font-medium mt-3')}>
                    {subtitle}
                </div>
            </div>
        </div>
    );
}

export default NoProcessesView;
