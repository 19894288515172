import cancelInterview from './cancelInterview';
import scheduleInterview from './scheduleInterview';
import talkToUs from './talkToUs';
import termination from './termination';
import workHistory from './workHistory';

export default {
    scheduleInterview,
    cancelInterview,
    termination,
    talkToUs,
    workHistory,
};
