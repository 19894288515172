import { clearAuthUser, registerAuthUser } from '@/_reducers/auth';
import Loader from '@/components/lotties/Loader';
import { firebaseAuth } from '@/firebase';
import useResetUserData from '@/hooks/useResetUserData';
import { cn } from '@/lib/utils';
import AnonymousRoutes from '@/routes/anonymousRoutes';
import { User } from 'firebase/auth';
import { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import useCurrentUserNameFromDB from './useNameFromDB';
import useSetSentryAuthData from './useSetSentryAuthData';

interface Props {
    children: ReactNode;
    clearAuthUser: () => void;
    registerAuthUser: (user: User) => void;
}

function AuthController({ children, registerAuthUser, clearAuthUser }: Props) {
    const [isReady, setIsReady] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    useSetSentryAuthData();
    useCurrentUserNameFromDB();
    const { reset: resetCandidateData } = useResetUserData();

    useEffect(() => {
        firebaseAuth
            .authStateReady()
            .then(() => {
                if (firebaseAuth.currentUser) {
                    registerAuthUser(firebaseAuth.currentUser);
                    setLoggedIn(true);
                }
            })
            .finally(() => {
                setIsReady(true);
            });
        firebaseAuth.onIdTokenChanged(async (user) => {
            if (user) {
                registerAuthUser(user);
                setLoggedIn(true);
            } else {
                clearAuthUser();
                setLoggedIn(false);
            }
        });
    }, [registerAuthUser, clearAuthUser]);

    useEffect(() => {
        if (!loggedIn) {
            resetCandidateData();
        }
    }, [loggedIn, resetCandidateData]);

    return (
        <>
            {!isReady && (
                <div
                    className={cn('flex justify-center items-center p-6 h-full w-full')}
                >
                    <Loader variant='primary' sizing='lg' delay={700} />
                </div>
            )}
            {isReady && (
                <>
                    {!loggedIn && <AnonymousRoutes />}
                    {loggedIn && children}
                </>
            )}
        </>
    );
}

const mapDispatchToProps = {
    registerAuthUser,
    clearAuthUser,
};

export default connect(null, mapDispatchToProps)(AuthController);
