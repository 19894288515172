import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

const UtmSourceContext = createContext<string>('');

export const UtmSourceProvider = ({ children }: { children: ReactNode }) => {
    const [utmSource, setUtmSource] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const utmSource = params.get('utm_source');
        if (utmSource) {
            setUtmSource(utmSource);
        }
    }, []);

    return (
        <UtmSourceContext.Provider value={utmSource}>
            {children}
        </UtmSourceContext.Provider>
    );
};

export const useUtmSource = () => useContext(UtmSourceContext);
