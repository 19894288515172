import { ResumeStatus } from '@/lib/api/v1';
import { serializeFetcherState } from '@/lib/utils';
import { createSelector } from 'reselect';

import { CandidateSliceState } from './base';

export const CurrentCandidate = createSelector(CandidateSliceState, (s) => s.data);

export const CurrentCandidateFetcherData = createSelector(CandidateSliceState, (s) =>
    serializeFetcherState(s.fetcher),
);

export const IsMissingResumeHistory = createSelector(CurrentCandidate, (candidate) => {
    if (
        [ResumeStatus.Generating, ResumeStatus.Ready].includes(
            candidate.resumeStatus as ResumeStatus,
        )
    ) {
        return false;
    }
    if (candidate?.experiences?.length ?? 0 > 1) {
        return false;
    }
    return true;
});
