import apiClient from '@/lib/api';
import { CustomHeaderName } from '@/lib/api/customHeaders';
import { useCallback } from 'react';

import { useRegisterFormContext } from './RegisterContext';
import { NonAuthRegisteredUser } from './types';
import useRegisterData from './useRegisterData';
import { canOnlyUpdatePhoneNumber } from './utils';

function useRegisterAction() {
    const { preAuthUser, setPreAuthUser } = useRegisterFormContext();
    const registerData = useRegisterData();
    const { phone } = registerData;

    const fixRegisteredPhone = useCallback(
        async (token: string, preAuthUser: NonAuthRegisteredUser) => {
            await apiClient
                .PUT('/api/v1/public/register/phone', {
                    body: { phone },
                    headers: {
                        [CustomHeaderName.RegistrationToken]: token,
                    },
                })
                .then(() => {
                    setPreAuthUser({ ...preAuthUser, phone });
                });
        },
        [phone, setPreAuthUser],
    );

    const register = useCallback(async () => {
        const registerNewUser = async () => {
            await apiClient
                .POST('/api/v1/public/register', {
                    body: registerData,
                })
                .then((response) => {
                    const { token, exp } = response.data!;
                    setPreAuthUser({
                        phone: registerData.phone,
                        isReregistration: false,
                        token: { value: token, exp },
                    });
                });
        };
        if (preAuthUser && canOnlyUpdatePhoneNumber(preAuthUser)) {
            try {
                return await fixRegisteredPhone(preAuthUser.token!.value, preAuthUser);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                if (error?.responseStatusCode === 401) {
                    setPreAuthUser(null);
                } else {
                    throw error;
                }
            }
        }
        await registerNewUser();
    }, [registerData, setPreAuthUser, preAuthUser, fixRegisteredPhone]);

    return register;
}

export default useRegisterAction;
