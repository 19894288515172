import { CampaignEventName } from '@/adCampaignTracking';
import useTrackAdsCampaignEvent from '@/adCampaignTracking/useTrackAdsCampaignEvent';
import { OnboardingFormStep } from '@/lib/types';
import { cn } from '@/lib/utils';
import { useEffect } from 'react';

import { useRegisterFormContext } from './RegisterContext';
import AdditionalAttributesStep from './steps/AdditionalAttributesStep';
import CareSettingsStep from './steps/CareSettingsStep';
import CodeVerificationStep from './steps/CodeVerificationStep';
import EmailStep from './steps/EmailStep';
import JobTypesStep from './steps/JobTypesStep';
import LongTermStep from './steps/LongTermStep';
import NameStep from './steps/NameStep';
import PhoneNumberStep from './steps/PhoneNumberStep';
import ProfessionStep from './steps/ProfessionStep';
import ResumeStep from './steps/ResumeStep';
import SalaryStep from './steps/SalaryStep';
import ShiftsStep from './steps/ShiftsStep';
import YearsExpStep from './steps/YearsExpStep';
import ZipCodeStep from './steps/ZipCodeStep';

function RegisterPageContent() {
    const { currentStep } = useRegisterFormContext();
    const trackAdsCampaignEvent = useTrackAdsCampaignEvent();
    useEffect(() => {
        trackAdsCampaignEvent(CampaignEventName.StartedRegistrationForm);
    }, [trackAdsCampaignEvent]);
    return (
        <>
            <div className={cn('relative w-full h-full overflow-hidden')}>
                {currentStep === OnboardingFormStep.LongTermInterest && <LongTermStep />}
                {currentStep === OnboardingFormStep.JobTypes && <JobTypesStep />}
                {currentStep === OnboardingFormStep.Profession && <ProfessionStep />}
                {currentStep === OnboardingFormStep.Shifts && <ShiftsStep />}
                {currentStep === OnboardingFormStep.CareSettings && <CareSettingsStep />}
                {currentStep === OnboardingFormStep.AdditionalAttrs && (
                    <AdditionalAttributesStep />
                )}
                {currentStep === OnboardingFormStep.YearsExp && <YearsExpStep />}
                {currentStep === OnboardingFormStep.ZipCode && <ZipCodeStep />}
                {currentStep === OnboardingFormStep.Name && <NameStep />}
                {currentStep === OnboardingFormStep.Email && <EmailStep />}
                {currentStep === OnboardingFormStep.PhoneNumber && <PhoneNumberStep />}
                {currentStep === OnboardingFormStep.CodeVerify && (
                    <CodeVerificationStep />
                )}
                {currentStep === OnboardingFormStep.Salary && <SalaryStep />}
                {currentStep === OnboardingFormStep.Resume && <ResumeStep />}
            </div>
        </>
    );
}

export default RegisterPageContent;
