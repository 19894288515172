import { LoadingButton, useToast } from '@/components/ui';
import { cn, extractErrorMsg } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogStrings } from '@/strings';
import { RecentJob } from '@/workHistory/types';
import useSubmitWorkExperience from '@/workHistory/useSubmitWorkExperience';
import { isValidRecentJobData } from '@/workHistory/utils';

import { DialogStep, SubmittableStep } from './types';

const strings = dialogStrings.workHistory;

interface Props {
    candidateId: string;
    step: SubmittableStep;
    cvFile: File | null;
    recentJob: RecentJob;
    recentJobDateError: string;
    onSuccess: (step: SubmittableStep) => void;
    submitResumeSideEffect?: () => void;
}

function UpdateWorkHistoryDialogFooter({
    step,
    candidateId,
    cvFile,
    onSuccess,
    recentJob,
    recentJobDateError,
    submitResumeSideEffect,
}: Props) {
    const { toast } = useToast();
    const submitWorkExperience = useSubmitWorkExperience();

    const submitResume = async () => {
        submitResumeSideEffect && submitResumeSideEffect();
        onSuccess(DialogStep.CV);
    };

    const submitExperience = async () => {
        await submitWorkExperience({
            ...recentJob,
            endDate: recentJob.endDate!,
            startDate: recentJob.startDate!,
            candidateId,
        })
            .then(() => {
                onSuccess(DialogStep.RecentJob);
            })
            .catch((error) => {
                toast({
                    title: strings.lastExperience.failureTitle,
                    description: extractErrorMsg(error),
                    variant: 'destructive',
                });
            });
    };

    const disabled =
        (step === DialogStep.CV && !cvFile) ||
        (step === DialogStep.RecentJob &&
            !isValidRecentJobData(recentJob, recentJobDateError));

    return (
        <div className={cn('w-full flex justify-center')}>
            <LoadingButton
                className={cn('w-full max-w-[400px] text-14 font-bold')}
                size={'md'}
                disabled={disabled}
                onClick={async () => {
                    trackEvent(MixpanelEvent.ResumeClickSubmitForm, { step });
                    const submitFunc =
                        step === DialogStep.CV ? submitResume : submitExperience;
                    await submitFunc();
                }}
            >
                {strings.submitButton}
            </LoadingButton>
        </div>
    );
}

export default UpdateWorkHistoryDialogFooter;
