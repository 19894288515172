import { ArrowBack, CarIcon } from '@/components/svgs';
import useOpenDialog from '@/dialogs/useOpenDialog';
import { DivAttributes } from '@/lib/base';
import { ComputedProcessStep, DialogName, Process } from '@/lib/types';
import { cn, getComputedProcessStep, getSelectedJobMatch } from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { processes as processesStrings } from '@/strings';

import ProcessActions from '../ProcessActions/ProcessActions';
import ProcessCardHeader from './header';

const strings = processesStrings.processCard;

interface CardProps {
    process: Process;
    isVertical: boolean;
    rootProps?: DivAttributes;
}

function ProcessCard({ process, rootProps, isVertical }: CardProps) {
    const { openDialog } = useOpenDialog(DialogName.ProcessDetails);
    const jm = getSelectedJobMatch(process.jobMatches);
    const facility = jm?.facility;
    const shouldShowEventActions = isVertical;
    const hasEventActions =
        getComputedProcessStep(process) === ComputedProcessStep.InterviewRequested;

    return (
        <div
            {...rootProps}
            className={cn(
                'relative min-w-[300px] w-[300px] rounded-2xl shadow-elevation-300 bg-white overflow-hidden cursor-pointer',
                'flex flex-col items-stretch',
                { 'max-w-full w-[362px]': isVertical },
                rootProps?.className,
            )}
            onClick={() => {
                trackEvent(MixpanelEvent.ExpandProcessCard, {
                    ...extractProcessDataForMixpanel(process),
                });
                openDialog({ processId: process.id });
            }}
        >
            <ProcessCardHeader process={process} />
            <div className={cn('p-6 flex-grow flex flex-col items-stretch gap-4')}>
                <div className={cn('text-18 font-bold whitespace-pre-line')}>
                    {facility?.type ? strings.facilityLabel.func(facility.type) : ''}
                </div>
                <div className={cn('flex flex-col gap-3')}>
                    {jm && (
                        <div className={cn('flex items-center gap-2')}>
                            <CarIcon className={cn('w-6 h-6')} />
                            <div
                                className={cn(
                                    'text-13-23 font-medium whitespace-pre-line',
                                )}
                            >
                                {strings.commuteLabel.func(jm.commuteDuration)}
                            </div>
                        </div>
                    )}
                </div>
                <div className={cn('flex items-center gap-2')}>
                    <div className={cn('text-13-23 font-bold')}>{strings.learnMore}</div>
                    <ArrowBack className={cn('rotate-180')} />
                </div>
            </div>
            <div
                className={cn('flex items-center p-7 border-t border-neutral100', {
                    'border-none pt-3': shouldShowEventActions && hasEventActions,
                    'h-24': !isVertical,
                })}
            >
                <ProcessActions
                    process={process}
                    shouldShowEventActions={shouldShowEventActions}
                    contextComponentName={'ProcessCardThumbnail'}
                    isProcessExpaneded={false}
                />
            </div>
        </div>
    );
}

export default ProcessCard;
