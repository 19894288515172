export enum CustomHeaderName {
    Impersonated = 'X-Impersonated-User',
    ImpersonatedType = 'X-Impersonated-User-Type',
    TraceId = 'X-Trace-Id',
    RegistrationToken = 'X-Registration-Token',
}

interface CustomHeaders {
    [CustomHeaderName.Impersonated]?: string;
    [CustomHeaderName.ImpersonatedType]?: string;
}

const customHeaders: CustomHeaders = {};

export default customHeaders;
