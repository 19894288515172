const strings = {
    loginTitle: 'Sign in to Carefam',
    loginSubtitle: 'Enter your phone number',
    submitButton: 'Next',
    linkToSignUp: 'Sign up',
    codeVerification: {
        title: 'Enter the 6-digit code',
        subtitle: {
            func: (formattedPhone: string) => `We sent it to ${formattedPhone}`,
            example: ['+1 123 456 7890'],
        },
        resendCodeButton: 'Resend code',
    },
};

export default strings;
