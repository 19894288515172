import { DialogName } from '@/lib/types';
import { Fragment, ReactNode } from 'react';

import CancelInterviewDialogProvider from './cancelInterview/CancelInterviewDialogProvider';
import DialogsContextProvider from './dialogsContext';
import ProcessDetailsDialogProvider from './processDetails/ProcessDetailsDialogProvider';
import ScheduleInterviewDialogProvider from './scheduleInterview/ScheduleInterviewDialogProvider';
import SupportDialogProvider from './support/SupportDialogProvider';
import TerminateProcessDialogProvider from './terminateProcess/TerminateProcessDialogProvider';
import UpdateWorkHistoryDialogProvider from './updateWorkHistory/UpdateWorkHistoryDialogProvider';

const dialogsOrder = [
    DialogName.ProcessDetails,
    DialogName.ScheduleInterview,
    DialogName.CancelInterview,
    DialogName.TerminateProcess,
    DialogName.UpdateWorkHistory,
    DialogName.Support,
];

function DialogsProvider({ children }: { children: ReactNode }) {
    return (
        <DialogsContextProvider>
            {dialogsOrder.map((dialog) => (
                <Fragment key={dialog}>
                    {dialog === DialogName.ProcessDetails && (
                        <ProcessDetailsDialogProvider />
                    )}
                    {dialog === DialogName.ScheduleInterview && (
                        <ScheduleInterviewDialogProvider />
                    )}
                    {dialog === DialogName.CancelInterview && (
                        <CancelInterviewDialogProvider />
                    )}
                    {dialog === DialogName.TerminateProcess && (
                        <TerminateProcessDialogProvider />
                    )}
                    {dialog === DialogName.Support && <SupportDialogProvider />}
                    {dialog === DialogName.UpdateWorkHistory && (
                        <UpdateWorkHistoryDialogProvider />
                    )}
                </Fragment>
            ))}
            {children}
        </DialogsContextProvider>
    );
}

export default DialogsProvider;
