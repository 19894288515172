import { cn } from '@/lib/utils';

import CompeteYourProfileBlock from './CompeteYourProfileBlock';
import ProcessesView from './ProcessesView';

function JustMissingResumeView() {
    return (
        <div className={cn('w-full h-full flex flex-col items-stretch')}>
            <CompeteYourProfileBlock rootProps={{ className: cn('pt-8 pb-0') }} />
            <ProcessesView />
        </div>
    );
}

export default JustMissingResumeView;
