import { ArrowLeft } from '@/components/svgs';
import { Button } from '@/components/ui';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogStrings } from '@/strings';
import UploadCVButton from '@/workHistory/UploadCVButton';
import { useState } from 'react';

import OrDelimiter from './OrDelimiter';

const strings = dialogStrings.workHistory;

interface Props {
    goToRecentJob: () => void;
    candidateId: string;
    cvFile: File | null;
    setCvFile: (cvFile: File | null) => void;
    setSubmitResumeSideEffect: (value: () => void) => void;
}

function UploadResumeStep({
    goToRecentJob,
    candidateId,
    cvFile,
    setCvFile,
    setSubmitResumeSideEffect,
}: Props) {
    const [hasCvFileError, setHasCvFileError] = useState(false);
    const [isLoadingCvFile, setIsLoadingCvFile] = useState(false);
    const { refresh: refreshCandidate } = useCurrentCandidate();

    const handleCvFileChange = (file: File | null) => {
        if (file) {
            trackEvent(MixpanelEvent.ResumeUploadResumeFile, {
                filename: file.name,
            });
        } else {
            trackEvent(MixpanelEvent.ResumeClearResumeFile);
        }
        setCvFile(file);
    };

    return (
        <div className={cn('flex flex-col p-10 pt-4 pb-14 items-stretch')}>
            <div className={cn('relative')}>
                <UploadCVButton
                    cvFile={cvFile}
                    candidateId={candidateId}
                    onFileChange={handleCvFileChange}
                    onFileSelect={(file: File) => {
                        trackEvent(MixpanelEvent.ResumeSelectResumeFile, {
                            filename: file.name,
                        });
                    }}
                    onOpenSelection={() => {
                        trackEvent(MixpanelEvent.ResumeClickUploadResume);
                    }}
                    onErrorChange={(e) => setHasCvFileError(!!e)}
                    onLoadingChange={setIsLoadingCvFile}
                    errorElemProps={{ className: cn('absolute -bottom-16') }}
                    onUploadSuccess={(triggerUploadedCb) =>
                        setSubmitResumeSideEffect(async () => {
                            await triggerUploadedCb();
                            refreshCandidate();
                        })
                    }
                />
            </div>
            <div
                className={cn(
                    'transition-opacity duration-200 opacity-100 mt-14',
                    'flex flex-col gap-14',
                    {
                        'opacity-0 pointer-events-none':
                            !!cvFile || hasCvFileError || isLoadingCvFile,
                    },
                )}
            >
                <OrDelimiter />
                <Button
                    className={cn('flex items-center gap-4')}
                    size={'lg'}
                    variant={'secondary'}
                    onClick={goToRecentJob}
                >
                    {strings.goToRecentJob}
                    <ArrowLeft className={cn('rotate-180')} />
                </Button>
            </div>
        </div>
    );
}

export default UploadResumeStep;
