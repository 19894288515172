import { RootState } from '@/_helpers/store';
import { getAllEnumValues } from '@/lib/base';
import { FetchModelStatus, ProcessApiModel } from '@/lib/types';
import { extractErrorMsg, generateEmptyFetcherState } from '@/lib/utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { keyBy, mapValues } from 'lodash-es';

import { FetchProcessesFunc, FetcherName, ProcessesState } from './types';

export const saveProcesses = createAsyncThunk<
    ProcessApiModel[],
    { fetcherId: FetcherName; fetchFunction: FetchProcessesFunc },
    { state: RootState }
>('processes/fetchProcesses', async ({ fetchFunction }, { rejectWithValue }) => {
    try {
        return await fetchFunction();
    } catch (error) {
        return rejectWithValue(extractErrorMsg(error));
    }
});

const fetcherNames = getAllEnumValues(FetcherName);

const initialState = {
    data: {},
    fetchers: mapValues(
        keyBy(fetcherNames, (n) => n),
        generateEmptyFetcherState,
    ),
} as ProcessesState;

const processesSlice = createSlice({
    name: 'processes',
    initialState,
    reducers: {
        updateProcess: (state, action: PayloadAction<ProcessApiModel>) => {
            const processId = action.payload.id;
            if (action.payload) {
                state.data[processId] = action.payload;
            }
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveProcesses.pending, (state, action) => {
                const { fetcherId } = action.meta.arg;
                state.fetchers[fetcherId].status = FetchModelStatus.LOADING;
            })
            .addCase(saveProcesses.fulfilled, (state, action) => {
                const { fetcherId } = action.meta.arg;
                const fetcherState = state.fetchers[fetcherId];
                fetcherState.status = FetchModelStatus.SUCCESS;
                fetcherState.initiated = true;
                if (action.payload) {
                    state.data = {
                        ...state.data,
                        ...keyBy(action.payload, 'id'),
                    };
                }
            })
            .addCase(saveProcesses.rejected, (state, action) => {
                const { fetcherId } = action.meta.arg;
                const fetcherState = state.fetchers[fetcherId];
                fetcherState.status = FetchModelStatus.FAILURE;
                fetcherState.error = action.error.message;
            });
    },
});

export default processesSlice;
