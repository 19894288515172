import { Dialog, PageLoader, useToast } from '@/components/ui';
import useProcessAvailability from '@/fetchers/useProcessAvailability';
import { DateIsoString, DayString, dayStringToIsoString } from '@/lib/base';
import { InterviewRelatedProcess, ScheduleInterviewDialogVariant } from '@/lib/types';
import {
    cn,
    extractErrorMsg,
    getEventTypeForProcess,
    getProcessDisplayName,
    keysOf,
} from '@/lib/utils';
import { dialogs as dialogStrings } from '@/strings';
import { useEffect, useState } from 'react';

import SuccessContent from '../common/SuccessContent';
import TalkToUsContent from '../support/TalkToUsContent';
import { BaseDialogPropsCommon } from '../types';
import SelectDateContent from './SelectDateContent';
import SelectTimeSlotContent from './SelectTimeSlotContent';
import ScheduleInterviewDialogHeader from './header';
import { getMixpanelEventTrackers } from './mixpanelEvents';
import { DialogStep } from './types';
import {
    dateToStartOfMonthIso,
    getCurrentMonthStart,
    getEventSlotsByDay,
    getMonthAndYear,
} from './utils';

const strings = dialogStrings.scheduleInterview;

interface Props extends BaseDialogPropsCommon {
    process: InterviewRelatedProcess;
    submitSchedule: (timeSlot: DateIsoString) => Promise<void>;
    variant: ScheduleInterviewDialogVariant;
}

function ScheduleInterviewDialog({
    process,
    isOpen,
    setOpen,
    submitSchedule,
    variant,
}: Props) {
    const { toast } = useToast();
    const { isLoading, initiated, eventSlots } = useProcessAvailability(process);
    const [step, setStep] = useState(DialogStep.TimeSelection);
    const [selectedDay, setSelectedDay] = useState<DayString | null>(null);
    const [monthAnchor, setMonthAnchor] = useState(getCurrentMonthStart());
    const eventType = getEventTypeForProcess(process)!;

    const eventSlotsByDay = getEventSlotsByDay(eventSlots);
    const availableDays = keysOf(eventSlotsByDay);
    const firstAvDay = availableDays[0];

    const {
        trackGoBack,
        trackGoToNextMonth,
        trackGoToPrevMonth,
        trackClickTalkToUs,
        trackSelectDate,
        trackSelectTimeSlot,
        trackSubmitTimeSlot,
    } = getMixpanelEventTrackers(process);

    const onTimeSelectionSubmit = async (timeSlot: DateIsoString) => {
        trackSubmitTimeSlot(timeSlot);
        await submitSchedule(timeSlot)
            .then(() => setStep(DialogStep.ScheduleSuccess))
            .catch((error) => {
                toast({
                    title: strings.submit.failureTitle,
                    description: extractErrorMsg(error),
                    variant: 'destructive',
                });
            });
    };
    const isReschedule = variant === ScheduleInterviewDialogVariant.Reschedule;

    const slotSelectionVariantStrings = isReschedule
        ? strings.timeSlotSelection.reschedule
        : strings.timeSlotSelection;

    useEffect(() => {
        return () => {
            if (!isOpen) {
                setStep(DialogStep.TimeSelection);
                setSelectedDay(null);
            }
        };
    }, [isOpen]);

    useEffect(() => {
        if (!firstAvDay) return;
        const firstAvDayIso = dayStringToIsoString(firstAvDay);
        const { month: firstAvMonth, year: firstAvYear } =
            getMonthAndYear(firstAvDayIso);
        const { month, year } = getMonthAndYear(getCurrentMonthStart());
        if (month !== firstAvMonth || year !== firstAvYear) {
            setMonthAnchor(dateToStartOfMonthIso(new Date(firstAvDayIso)));
        }
    }, [firstAvDay]);

    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            customHeader={
                <ScheduleInterviewDialogHeader
                    process={process}
                    isReschedule={isReschedule}
                    trackGoBack={trackGoBack}
                    step={step}
                    setStep={setStep}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                />
            }
        >
            <>
                {isLoading && !initiated && (
                    <PageLoader delay={0} className={cn('h-[480px] max-h-full')} />
                )}
                {initiated && (
                    <>
                        {step === DialogStep.TimeSelection && (
                            <>
                                {selectedDay === null ? (
                                    <SelectDateContent
                                        goToTalkToUs={() => {
                                            trackClickTalkToUs();
                                            setStep(DialogStep.TalkToUs);
                                        }}
                                        onDaySelect={(day) => {
                                            trackSelectDate(day);
                                            setSelectedDay(day);
                                        }}
                                        monthAnchor={monthAnchor}
                                        availableDays={availableDays}
                                        setMonthAnchor={setMonthAnchor}
                                        trackGoToNextMonth={trackGoToNextMonth}
                                        trackGoToPrevMonth={trackGoToPrevMonth}
                                    />
                                ) : (
                                    <SelectTimeSlotContent
                                        slots={
                                            selectedDay
                                                ? eventSlotsByDay[selectedDay]
                                                : []
                                        }
                                        eventType={eventType}
                                        submit={onTimeSelectionSubmit}
                                        variant={variant}
                                        trackSelectTimeSlot={trackSelectTimeSlot}
                                    />
                                )}
                            </>
                        )}
                        {step === DialogStep.TalkToUs && (
                            <TalkToUsContent
                                sectionName={`Scheduling Interview at ${getProcessDisplayName(process) ?? ''}`}
                                submitCallback={() =>
                                    setStep(DialogStep.TalkToUsSuccess)
                                }
                            />
                        )}
                        {step === DialogStep.ScheduleSuccess && (
                            <SuccessContent
                                title={slotSelectionVariantStrings.successTitle}
                                subtitle={strings.timeSlotSelection.successSubtitle}
                            />
                        )}
                        {step === DialogStep.TalkToUsSuccess && (
                            <SuccessContent
                                title={strings.talkToUs.successTitle}
                                subtitle={strings.talkToUs.successSubtitle}
                            />
                        )}
                    </>
                )}
            </>
        </Dialog>
    );
}

export default ScheduleInterviewDialog;
