export enum DialogName {
    ProcessDetails = 'processDetails',
    ScheduleInterview = 'scheduleInterview',
    CancelInterview = 'cancelInterview',
    TerminateProcess = 'terminateProcess',
    UpdateWorkHistory = 'updateWorkHistory',
    Support = 'support',
}

export type DialogCallback = () => unknown;

export interface DialogCallbackConfig {
    callback: DialogCallback;
    dialog: DialogName;
}

export enum ScheduleInterviewDialogVariant {
    AcceptInterview = 'acceptInterview',
    Reschedule = 'reschedule',
}

export enum TerminationDialogVariant {
    Termination = 'termination',
    DeclineInterview = 'declineInterview',
}

export enum SupportTriggerSection {
    SupportButton = 'supportButton',
    OrientationIssues = 'orientationIssues',
}
