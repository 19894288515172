import { PageLoader } from '@/components/ui';
import EventSkeleton from '@/events/EventSkeleton';
import useProcessModels from '@/fetchers/useProcessesModels';
import { ScheduleIntroCallView, useRegisterScheduledEvent } from '@/introCall';
import { cn, isInProgress, valuesOf } from '@/lib/utils';

import ScreeningSectionFooter from './ScreeningSectionFooter';

function ScheduleIntroCallSection() {
    const { isLoading, registerEvent } = useRegisterScheduledEvent();
    const { isReady: isProcessesReady, data: processes } = useProcessModels();
    const hasOngoingProcesses = valuesOf(processes).some(isInProgress);

    return (
        <>
            {isLoading && (
                <div className={cn('relative w-full py-12 px-8 bg-accent300')}>
                    <EventSkeleton />
                </div>
            )}
            {!isLoading && (
                <>
                    {!isProcessesReady && <PageLoader />}
                    {isProcessesReady && (
                        <ScheduleIntroCallView
                            registerEvent={registerEvent}
                            inProgressCandidateMsg={hasOngoingProcesses}
                        />
                    )}
                </>
            )}
            <ScreeningSectionFooter withDelimiter={!isLoading} />
        </>
    );
}

export default ScheduleIntroCallSection;
