import Logger from '@/_helpers/logger';
import { useAppDispatch } from '@/_helpers/store';
import {
    AddCandidateExperiencePayload,
    addCandidateExperience,
} from '@/_reducers/candidate';
import apiClient from '@/lib/api';
import { extractErrorMsg } from '@/lib/utils';
import { useCallback } from 'react';

export default function useSubmitWorkExperience() {
    const dispatch = useAppDispatch();
    return useCallback(
        async (params: AddCandidateExperiencePayload) => {
            await dispatch(addCandidateExperience(params));
            apiClient
                .POST('/api/v1/candidates/{candidate_id}/resume/generate', {
                    params: { path: { candidate_id: params.candidateId } },
                    body: { noWait: true },
                })
                .catch((error) => {
                    Logger.error(
                        extractErrorMsg(error),
                        'Request to initiate resume-generator failed',
                    );
                });
        },
        [dispatch],
    );
}
