import { Profession } from '@/lib/types';

import { careSettingsStrings, jobTypeStrings, shiftStrings } from '../common';

const professionToButtonLabel: Record<Profession, string> = {
    [Profession.CNA]: 'CNA',
    [Profession.LPN]: 'LPN/LVN',
    [Profession.RN]: 'RN',
    [Profession.NP]: 'NP',
    [Profession.HHA]: 'HHA',
    [Profession.Other]: 'Other',
};

const professionToArticle: Record<Profession, string> = {
    [Profession.CNA]: 'a',
    [Profession.LPN]: 'an',
    [Profession.RN]: 'an',
    [Profession.NP]: 'an',
    [Profession.HHA]: 'an',
    [Profession.Other]: 'a',
};

const strings = {
    nextStep: 'Next',
    longTermTitle: 'Are you open to a permanent long-term care job?',
    jobTypesTitle: 'What type of position are you looking for?',
    profession: {
        title: 'What is your profession?',
        customProfessionPlaceholder: 'Specify other...',
    },
    shiftsTitle: 'Which shifts are you available to work?',
    careSettingsTitle: 'Which care settings are you open to working in?',
    selectManyDescription: 'Select all that apply',
    yearsExp: {
        title: {
            func: (prof: Profession) =>
                `How many years have you been working as ${professionToArticle[prof]} ${professionToButtonLabel[prof]}?`,
            example: [Profession.LPN],
        },
        customProfTitle: {
            func: (prof: string) => `How many years have you been working as "${prof}"?`,
            example: ['Dental Assistant'],
        },
    },
    additionalAttributes: {
        title: 'Please answer the following',
        hasCarQuestion: 'Do you own a car?',
        hasDrivingLicenseQuestion: 'Do you possess a valid driving license?',
    },
    zipcode: {
        title: 'What is your zip code?',
        subtitle: 'This helps us find jobs near you',
        placeholder: '11211',
        error: 'Please enter a valid zipcode',
    },
    fullname: {
        title: 'What is your name?',
        subtitle: 'Please enter your first and last name below',
        placeholder: 'John Doe',
        error: 'Please enter your first and last name',
    },
    salary: {
        title: 'What hourly salary would make you consider new opportunities?',
        subtitle: "Skip if you don't have a number in mind yet",
        errors: {
            moreThanZero: 'Salary should be greater than 0',
        },
    },
    email: {
        title: 'Where can we send your job matches? (email)',
        subtitle: 'You can opt out anytime :)',
        placeholder: 'john-doe@gmail.com',
        error: 'Please enter a valid email address',
    },
    phone: {
        title: 'What is your phone number?',
        subtitle: 'It is used for interview scheduling and sign-in',
        error: 'Please enter a valid phone number',
    },
    verificationCode: {
        title: 'Enter the 6-digit code',
        subtitle: {
            func: (formattedPhone: string) => `We sent it to ${formattedPhone}`,
            example: ['+1 123 456 7890'],
        },
        resendCodeButton: 'Resend code',
        resendCodeButtonDisabled: {
            part1: 'Resend code in',
            part2: {
                func: (seconds: number) => `0:${String(seconds).padStart(2, '0')}`,
                examples: [[20], [9]],
            },
        },
        error: 'Could not verify code',
        invalidCodeError: 'Please enter the correct code',
    },
    registerSuccess: {
        title: 'Verified phone number successfully!',
        subtitle: {
            func: (formattedPhone: string) =>
                `You are logged in with the phone number ${formattedPhone}`,
            example: ['+11234567890'],
        },
    },
    resume: {
        haveResume: {
            title: 'Do you have a resume ready?',
            subtitle: 'Congrats - this is the last step 🎉',
        },
        uploadResume: {
            title: 'Upload your resume',
        },
        lastExperience: {
            title: 'No resume? No problem!',
            subtitle: 'Add information about your most recent work experience',
        },
    },
    answerYes: 'Yes',
    answerNo: 'No',
    jobTypes: {
        ...jobTypeStrings,
    },
    professionButtons: {
        ...professionToButtonLabel,
    },
    shiftLabels: {
        ...shiftStrings,
    },
    careSettingsLabels: {
        ...careSettingsStrings,
    },
};

export default strings;
