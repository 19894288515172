import { genericToasts } from '../common';

const strings = {
    orDelimiter: 'OR',
    submitButton: 'Submit',
    header: {
        title: 'Update your work history',
        subtitle:
            'Updating your work history is essential for receiving interview requests',
    },
    goToRecentJob: 'Provide recent work experience',
    success: {
        resumeUploadTitle: 'Resume uploaded successfully',
        experienceUploadTitle: 'Recent work experience saved successfully',
        subtitle: 'Hooray 🎉 Now you can start recieving interview requests!',
    },
    lastExperience: {
        subtitle: 'Add information about your most recent work experience',
        failureTitle: genericToasts.failure.failureTitle,
    },
};

export default strings;
