const strings = {
    shouldRegister:
        'This phone number does not appear in our system. Sign up for a new account.',
    invalidPhone: 'Invalid phone number.',
    networkError: 'Connection issue. Check your connection and try again.',
    codeVerification: {
        invalidCode: 'Please enter the correct code',
        codeExpired: 'This code has expired',
        default: 'Could not verify code',
    },
};

export default strings;
