import Loader from '@/components/lotties/Loader';
import { UploadedFileIcon } from '@/components/svgs';
import { Button } from '@/components/ui';
import { cn } from '@/lib/utils';
import { reusableComponents as componentsStrings } from '@/strings';
import { UploadIcon, XIcon } from 'lucide-react';
import { useRef, useState } from 'react';

const strings = componentsStrings.fileUploadButton;

export interface FileUploadButtonProps {
    onFileSelect: (file: File) => void | Promise<void>;
    onFileClear: () => void;
    label: string;
    onOpenSelection?: () => void;
    fileExt?: string[];
    preSelectedFile?: File | null;
    error?: string;
    clearError?: () => void;
    errorElemProps?: React.HTMLAttributes<HTMLParagraphElement>;
    onLoadingChange?: (loading: boolean) => void;
}

const splitFileName = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
        return { name: fileName, extension: '' };
    }
    const name = fileName.substring(0, lastDotIndex);
    const extension = fileName.substring(lastDotIndex + 1);
    return { name, extension };
};

function EmptyContent({ label }: { label: string }) {
    return (
        <div className={cn('flex items-center gap-5')}>
            <UploadIcon />
            <div className={cn('text-15')}>{label}</div>
        </div>
    );
}

function LoadingContent() {
    return (
        <div className={cn('flex items-center gap-5')}>
            <Loader sizing={'md'} variant={'primary'} />
            <span className={cn('text-15 font-bold')}>{strings.uploading}</span>
        </div>
    );
}

function UploadedFileContent({ file, onClose }: { file: File; onClose: () => void }) {
    const { name, extension } = splitFileName(file.name);
    return (
        <div className={cn('w-full flex items-center justify-between gap-5')}>
            <div className={cn('flex-grow overflow-hidden')}>
                <div className={cn('flex flex-nowrap items-center gap-5 ')}>
                    <div className='flex-shrink-0'>
                        <UploadedFileIcon />
                    </div>
                    <div
                        className={cn(
                            'relative flex-grow flex items-center text-15 font-bold overflow-hidden',
                        )}
                    >
                        <div
                            className={cn(
                                'text-ellipsis overflow-hidden whitespace-nowrap',
                            )}
                        >
                            {decodeURIComponent(name)}
                        </div>
                        <div className={cn('')}>{extension ? `.${extension}` : ''}</div>
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    'flex-shrink-0 w-10 h-10 flex items-center justify-center',
                )}
            >
                <XIcon
                    className={cn('cursor-pointer')}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                    }}
                />
            </div>
        </div>
    );
}

function FileUploadButton({
    label,
    onFileSelect,
    onFileClear,
    onOpenSelection,
    fileExt,
    preSelectedFile,
    error = '',
    clearError,
    errorElemProps,
    onLoadingChange,
}: FileUploadButtonProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(
        preSelectedFile ?? null,
    );

    const updateLoadingState = (val: boolean) => {
        setLoading(val);
        onLoadingChange && onLoadingChange(val);
    };

    const hasFile = selectedFile !== null;
    const fileUploaded = hasFile && !loading;
    const isEmpty = !hasFile && !loading;

    const handleFileClear = () => {
        onFileClear();
        clearError && clearError();
    };

    const clearFile = () => {
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        handleFileClear();
    };

    const handleButtonClick = () => {
        if (fileInputRef.current && isEmpty) {
            fileInputRef.current.click();
            onOpenSelection && onOpenSelection();
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        if (file) {
            setSelectedFile(file);
            updateLoadingState(true);
            try {
                await onFileSelect(file);
            } catch {
                clearFile();
            } finally {
                updateLoadingState(false);
            }
        } else {
            handleFileClear();
        }
    };

    const hasError = !!error;

    return (
        <>
            <Button
                className={cn(
                    'w-full',
                    { 'bg-accent200': fileUploaded },
                    { 'cursor-default': !isEmpty },
                    { 'border-red200': hasError },
                )}
                variant={'secondary'}
                size={'lg'}
                onClick={handleButtonClick}
            >
                {fileUploaded ? (
                    <UploadedFileContent file={selectedFile} onClose={clearFile} />
                ) : loading ? (
                    <LoadingContent />
                ) : (
                    <EmptyContent label={label} />
                )}
            </Button>
            <input
                type='file'
                ref={fileInputRef}
                onChange={handleFileChange}
                className={cn('hidden')}
                accept={fileExt ? fileExt.map((ext) => `.${ext}`).join(',') : undefined}
            />
            {hasError && (
                <p
                    {...errorElemProps}
                    className={cn(
                        'mt-8 font-medium text-red200 text-14',
                        errorElemProps?.className,
                    )}
                >
                    {error}
                </p>
            )}
        </>
    );
}

export default FileUploadButton;
