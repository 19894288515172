import Logger from '@/_helpers/logger';
import { InnerDateType } from '@/components/ui';
import useCandidateId from '@/hooks/useCandidateId';
import { getDateOrNull } from '@/lib/base';
import { OnboardingQuestion } from '@/lib/types';
import { cn, extractErrorMsg } from '@/lib/utils';
import { registration as strings } from '@/strings';
import LastWorkPlaceForm from '@/workHistory/LastWorkPlaceForm';
import useSubmitWorkExperience from '@/workHistory/useSubmitWorkExperience';
import { isValidRecentJobData } from '@/workHistory/utils';
import { useEffect, useState } from 'react';

import { useRegisterFormContext } from '../../RegisterContext';
import RegistrationStepLayout from '../../RegistrationStepLayout';
import StepTitle from '../../StepTitle';
import { debouncedTrackUpdateAnswerEvent } from '../../utils';

function LastWorkPlaceFormLayout({ goBack }: { goBack: () => void }) {
    const {
        setCustomGoBackAction,
        recentJobCompany: company,
        recentJobTitle: role,
        recentJobStart: startDateString,
        recentJobEnd: endDateString,
        recentJobIsCurrent: isCurrentJob,
        setRecentJobCompany: setCompany,
        setRecentJobTitle: setRole,
        setRecentJobStart: setStartDateString,
        setRecentJobEnd: setEndDateString,
        setRecentJobIsCurrent: setIsCurrentJob,
    } = useRegisterFormContext();
    const startDate = getDateOrNull(startDateString);
    const endDate = getDateOrNull(endDateString);
    const [dateError, setDateError] = useState('');
    const candidateId = useCandidateId();
    const submitWorkExperience = useSubmitWorkExperience();

    const canGoNext = isValidRecentJobData(
        { role, company, startDate, endDate, isCurrentJob },
        dateError,
    );

    const onSubmit = async () => {
        submitWorkExperience({
            company,
            role,
            isCurrentJob,
            startDate: startDate!,
            endDate: endDate!,
            candidateId,
        }).catch((error) => {
            Logger.error(extractErrorMsg(error), 'Failed to submit work experience');
        });
    };

    useEffect(() => {
        setCustomGoBackAction(goBack);
        return () => {
            setCustomGoBackAction(null);
        };
    }, [goBack, setCustomGoBackAction]);

    return (
        <RegistrationStepLayout canGoNext={canGoNext} sideEffect={onSubmit}>
            <div className={cn('w-full max-w-[400px] flex flex-col items-center')}>
                <div className={cn('w-full')}>
                    <StepTitle
                        title={strings.resume.lastExperience.title}
                        subtitle={strings.resume.lastExperience.subtitle}
                    />
                    <div className={cn('mt-10')}>
                        <LastWorkPlaceForm
                            company={company}
                            setCompany={setCompany}
                            role={role}
                            setRole={setRole}
                            startDate={startDate}
                            setStartDate={(date) =>
                                setStartDateString(date?.toISOString() ?? '')
                            }
                            endDate={endDate}
                            setEndDate={(date) =>
                                setEndDateString(date?.toISOString() ?? '')
                            }
                            onDateTextChange={(type, value) =>
                                debouncedTrackUpdateAnswerEvent({
                                    question:
                                        type === InnerDateType.Start
                                            ? OnboardingQuestion.RecentJobStart
                                            : OnboardingQuestion.RecentJobEnd,
                                    answer: value,
                                })
                            }
                            dateError={dateError}
                            setDateError={setDateError}
                            isCurrentJob={isCurrentJob}
                            setIsCurrentJob={setIsCurrentJob}
                        />
                    </div>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default LastWorkPlaceFormLayout;
