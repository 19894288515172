import { cn } from '@/lib/utils';
import { dialogs as strings } from '@/strings';

function OrDelimiter() {
    return (
        <div className={cn('relative flex items-center w-full gap-2')}>
            <div className={cn('flex-grow h-0 border-t border-black/20')} />
            <div className={cn('text-15 font-bold')}>
                {strings.workHistory.orDelimiter}
            </div>
            <div className={cn('flex-grow h-0 border-t border-black/20')} />
        </div>
    );
}

export default OrDelimiter;
