export enum CampaignEventName {
    StartedRegistrationForm = 'StartedSubmission',
    SubmittedPhoneNumber = 'SubmittedForm',
    RegisteredSuccessfully = 'RegisteredValidPhoneNumber',
    FinishedPostRegistrationForm = 'FinishedRegistrationForm',
}

export enum KnownUtmSource {
    Google = 'google',
    Facebook = 'facebook',
}

export type TrackCampaignEventFunc = (
    eventName: CampaignEventName,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventData?: Record<string, any>,
) => void;
