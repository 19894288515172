import { DayOfWeek } from '@/lib/api/v1';
import { Time } from '@/lib/base';

export enum TZ {
    UTC = 'UTC',
    ET = 'America/New_York',
}

export interface TimeInterval {
    start: Time;
    end: Time;
}
export interface TimeIntervalWithDay extends TimeInterval {
    day: DayOfWeek;
}
