import { EventType, ProcessStatus, ProcessSubstatus } from '@/lib/api/v1';
import { DateIsoString, DayString, TimeString } from '@/lib/base';
import {
    OnboardingFormStep,
    OnboardingQuestionWithAnswer,
    Process,
    ProcessEvent,
    TerminationDialogVariant,
} from '@/lib/types';
import { getProcessDisplayName } from '@/lib/utils';
import { mapValues } from 'lodash-es';
import mixpanel from 'mixpanel-browser';

import { isMixpanelOn, sendEventToLog } from './utils';

export enum MixpanelEvent {
    Login = 'Login',
    SignOut = 'Sign Out',
    VisitPage = 'Visit Page',
    NavigateHomePage = 'Navigate Homepage',
    NavigateStatusPage = 'Navigate Status Page',
    NavigateProfilePage = 'Navigate Profile Page',
    RegistrationStartStep = 'Registration: Start Step',
    RegistrationFinishStep = 'Registration: Finish Step',
    RegistrationClickNextStep = 'Registration: Click Next Step',
    RegistrationClickPrevStep = 'Registration: Click Previous Step',
    RegistrationUpdateAnswer = 'Registration: Update Answer',
    RegistrationAutoSkipPostRegistrationStep = 'Registration: Auto Skip Post Registration Step',
    RegistrationSubmitPhone = 'Registration: Submit Phone Number',
    RegistrationSubmitVerificationCode = 'Registration: Submit VerificationCode',
    RegistrationSubmitExistingPhoneNumber = 'Registration: Submit Existing Phone Number',
    RegistrationGetSubmitError = 'Registration: Get Submit Error',

    RegistrationClickResendCode = 'Registration: Click Resend Code',

    RegistrationClickUploadResume = 'Registration: Click Upload Resume',
    RegistrationSelectResumeFile = 'Registration: Select Resume File',
    RegistrationUploadResumeFile = 'Registration: Upload Resume File',
    RegistrationClearResumeFile = 'Registration: Clear Resume File',
    ResumeGetFileSizeError = 'Resume: Get File Size Error',

    ResumeClickUploadResume = 'Resume: Click Upload Resume',
    ResumeSelectResumeFile = 'Resume: Select Resume File',
    ResumeUploadResumeFile = 'Resume: Upload Resume File',
    ResumeClearResumeFile = 'Resume: Clear Resume File',
    ResumeGoToRecentJobForm = 'Resume: Go To Recent Job Form',
    ResumeGoBackToUploadStep = 'Resume: Go Back To Upload Step',
    ResumeClickSubmitForm = 'Resume: Click Submit Form',
    OpenDialogCompleteYourProfile = 'Open Dialog Complete Your Profile',

    ShowRecaptchaModal = 'Show Recaptcha Modal',
    HideRecaptchaModal = 'Hide Recaptcha Modal',
    FailedRecaptcha = 'Failed Recaptcha',
    SucceedRecaptcha = 'Succeed Recaptcha',
    CanceledRecaptcha = 'Canceled Recaptcha',

    HomePageViewSearchRadar = 'Homepage: View Search Radar',
    IntroCallPageClickSkipButton = 'Intro Call Page: Click Skip Button',
    GotIntroCallPageState = 'Got Intro Call Page State',
    VisitIntroCallSection = 'Visit Intro Call Section',
    IntroCallClickSchedule = 'Intro Call: Click Schedule',
    IntroCallClickCallUsNow = 'Intro Call: Click Call Us Now',
    IntroCallOpenActionsMenu = 'Intro Call: Open Actions Menu',
    IntroCallClickReschedule = 'Intro Call: Click Reschedule Meeting',
    IntroCallClickCancel = 'Intro Call: Click Cancel Meeting',
    IntroCallCancellationSubmit = 'Intro Call Cancellation: Submit Cancellation',
    IntroCallSyncRescheduledMeeting = 'Intro Call: Sync Rescheduled Meeting',
    CalendlyWidgetViewCalendar = 'Calendly Widget: View Calendar',
    CalendlyWidgetSelectSlot = 'Calendly Widget: Select Slot',
    CalendlyWidgetScheduleMeeting = 'Calendly Widget: Schedule Meeting',
    CalendlyWidgetClickGotIt = 'Calendly Widget: Click Got It',
    CalendlyWidgetIframeLoaded = 'Calendly Widget: Iframe Loaded',
    OpenUserMenu = 'Open User Menu',
    UserMenuClickSupport = 'User Menu: Click Support',
    UserMenuClickPrivacyPolicy = 'User Menu: Click Privacy Policy',

    ProcessCardUpdateImInterested = 'Process Card: Update Is Interested',
    ProcessCardClickAcceptInterview = 'Process Card: Click Accept Interview',
    ProcessCardClickDeclineInterview = 'Process Card: Click Decline Interview',
    ExpandProcessCard = 'Expand Process Card',
    CloseProcessCard = 'Close Process Card',
    ProcessCardZoomInMap = 'Process Card: Zoom In Map',
    ProcessCardZoomOutMap = 'Process Card: Zoom Out Map',
    ProcessCardOpenActionsMenu = 'Process Card: Open Actions Menu',
    ProcessActionsMenuClickTermination = 'Process Actions Menu: Click Termination',
    ProcessCardScrollToBottom = 'Process Card: Scroll To Content End',

    ViewSectionCurrentProcesses = 'View Section Current Processes',
    ViewSectionUpcomingEvents = 'View Section Upcoming Events',
    UpcomingEventOpenActionsMenu = 'Upcoming Event: Open Actions Menu',
    UpcomingEventActionsClickRescheduleInterview = 'Upcoming Event Actions: Click Reschedule Interview',
    UpcomingEventActionsClickCancelInterview = 'Upcoming Event Actions: Click Cancel Interview',
    UpcomingEventActionsClickHelpForOrientation = 'Upcoming Event Actions: Click Help For Orientation',

    ProfileStartFieldEdit = 'Profile: Start Field Edit',
    ProfileFinishFieldEdit = 'Profile: Finish Field Edit',
    ProfileUpdateSwitchValue = 'Profile: Update Switch Field Value',
    ProfileSubmitForm = 'Profile: Submit Form',

    CancelInterviewGoToCancelationForm = 'Cancel Interview: Click Need Different Time Button',
    CancelInterviewGoToTermination = 'Cancel Interview: Click Not Intereseted Button',
    CancelInterviewSubmitForm = 'Cancel Interview: Submit Form',

    ScheduleInterviewGoToNextMonth = 'Schedule Interview: Go To Next Month',
    ScheduleInterviewGoToPrevMonth = 'Schedule Interview: Go To Previous Month',
    ScheduleInterviewClickTalkToUs = 'Schedule Interview: Click Talk To Us',
    ScheduleInterviewSelectDate = 'Schedule Interview: Select Date',
    ScheduleInterviewSelectTime = 'Schedule Interview: Select Time',
    ScheduleInterviewGoBack = 'Schedule Interview: Go Back',
    ScheduleInterviewSubmitTime = 'Schedule Interview: Submit Time Slot',
    TalkToUsSubmitForm = 'Talk To Us: Submit Form',

    SelectTerminationReason = 'Select Termination Reason',
    SubmitTerminationReason = 'Submit Termination Reason',
    ConfirmProcessTermination = 'Confirm Process Termination',
    CancelProcessTermination = 'Cancel Process Termination',

    LoginSubmitPhoneNumber = 'Login: Submit Phone Number',
    LoginEnterCodeVerificationStep = 'Login: Enter Code Verification Step',
    LoginGoBack = 'Login: Go Back',
    LoginSubmitVerificationCode = 'Login: Submit Verification Code',
    LoginClickResendCode = 'Login: Click Resend Code',
    LoginGoToRegistration = 'Login: Go To Registration',

    // Techincal:
    WindowResize = 'Resize Window',
    AttachedRecaptchaToDom = 'Attached Recaptcha To DOM',
}

interface CurrentRegistrationStepData {
    current_step: OnboardingFormStep;
    current_step_index: number;
}

interface ProcessData {
    process_display_name: string;
    process_id: string;
    process_status: ProcessStatus;
    process_substatus: ProcessSubstatus | '';
}

export function extractProcessDataForMixpanel(process: Process): ProcessData {
    const { status, substatus, id } = process;
    return {
        process_display_name: getProcessDisplayName(process) ?? '',
        process_id: id,
        process_status: status,
        process_substatus: substatus ?? '',
    };
}

interface ProcessEventData {
    start_at: DateIsoString;
    event_type: EventType;
    process_display_name: string;
}

export function extractProcessEventDataForMixpanel(
    process: Process,
    event: ProcessEvent,
): ProcessEventData {
    const { eventType, startAt } = event;
    return {
        start_at: startAt,
        event_type: eventType,
        process_display_name: getProcessDisplayName(process) ?? '',
    };
}

export interface AllMixpanelEventsData {
    [MixpanelEvent.WindowResize]: {
        'Window Height': number;
        'Window Width': number;
    };
    [MixpanelEvent.VisitPage]: {
        page: string;
        path: string;
        search: string;
    };
    [MixpanelEvent.Login]: {
        phoneNumber: string;
    };
    [MixpanelEvent.RegistrationStartStep]: CurrentRegistrationStepData;
    [MixpanelEvent.RegistrationFinishStep]: CurrentRegistrationStepData;
    [MixpanelEvent.RegistrationClickNextStep]: CurrentRegistrationStepData;
    [MixpanelEvent.RegistrationClickPrevStep]: CurrentRegistrationStepData;
    [MixpanelEvent.RegistrationAutoSkipPostRegistrationStep]: CurrentRegistrationStepData;
    [MixpanelEvent.RegistrationUpdateAnswer]: OnboardingQuestionWithAnswer;
    [MixpanelEvent.RegistrationSubmitPhone]: {
        phone: string;
    };
    [MixpanelEvent.RegistrationSelectResumeFile]: {
        filename: string;
    };
    [MixpanelEvent.RegistrationUploadResumeFile]: {
        filename: string;
    };
    [MixpanelEvent.ResumeSelectResumeFile]: {
        filename: string;
    };
    [MixpanelEvent.ResumeUploadResumeFile]: {
        filename: string;
    };
    [MixpanelEvent.ResumeClickSubmitForm]: {
        step: string;
    };
    [MixpanelEvent.IntroCallClickReschedule]: {
        current_scheduled_time: DateIsoString;
    };
    [MixpanelEvent.IntroCallClickCancel]: {
        current_scheduled_time: DateIsoString;
    };
    [MixpanelEvent.IntroCallSyncRescheduledMeeting]: {
        prev_scheduled_time: DateIsoString;
        new_scheduled_time: DateIsoString;
    };
    [MixpanelEvent.ProcessCardUpdateImInterested]: ProcessData & {
        is_interested: boolean;
        via: string;
    };
    [MixpanelEvent.ProcessCardClickAcceptInterview]: ProcessData & {
        via: string;
    };
    [MixpanelEvent.ProcessCardClickDeclineInterview]: ProcessData & {
        via: string;
    };
    [MixpanelEvent.ExpandProcessCard]: ProcessData;
    [MixpanelEvent.ProcessActionsMenuClickTermination]: ProcessData;
    [MixpanelEvent.ProcessCardScrollToBottom]: ProcessData;
    [MixpanelEvent.ProcessCardZoomOutMap]: {
        prev_zoom_level: number;
        new_zoom_level: number;
    };
    [MixpanelEvent.ProcessCardZoomInMap]: {
        prev_zoom_level: number;
        new_zoom_level: number;
    };
    [MixpanelEvent.ProcessCardZoomInMap]: {
        prev_zoom_level: number;
        new_zoom_level: number;
    };
    [MixpanelEvent.VisitIntroCallSection]: {
        intro_call_status: string;
    };
    [MixpanelEvent.GotIntroCallPageState]: {
        page_state: string;
    };
    [MixpanelEvent.SelectTerminationReason]: ProcessData & {
        dialog_variant: TerminationDialogVariant;
        reason: string;
    };
    [MixpanelEvent.SubmitTerminationReason]: ProcessData & {
        dialog_variant: TerminationDialogVariant;
        reason: string;
    };
    [MixpanelEvent.ConfirmProcessTermination]: ProcessData & {
        dialog_variant: TerminationDialogVariant;
        reason: string;
    };

    [MixpanelEvent.CancelInterviewGoToCancelationForm]: ProcessData;
    [MixpanelEvent.CancelInterviewGoToTermination]: ProcessData;
    [MixpanelEvent.CancelInterviewSubmitForm]: ProcessData & {
        reason: string;
    };

    [MixpanelEvent.ScheduleInterviewGoToNextMonth]: ProcessData & {
        current_month: string;
    };
    [MixpanelEvent.ScheduleInterviewGoToPrevMonth]: ProcessData & {
        current_month: string;
    };
    [MixpanelEvent.ScheduleInterviewClickTalkToUs]: ProcessData;
    [MixpanelEvent.ScheduleInterviewSelectDate]: ProcessData & {
        selected_date: DayString;
    };
    [MixpanelEvent.ScheduleInterviewSelectTime]: ProcessData & {
        selected_time: TimeString;
        selected_timestamp: DateIsoString;
    };
    [MixpanelEvent.ScheduleInterviewSubmitTime]: ProcessData & {
        selected_time: TimeString;
        selected_timestamp: DateIsoString;
    };

    [MixpanelEvent.UpcomingEventOpenActionsMenu]: ProcessEventData;
    [MixpanelEvent.UpcomingEventActionsClickRescheduleInterview]: ProcessEventData;
    [MixpanelEvent.UpcomingEventActionsClickCancelInterview]: ProcessEventData;
    [MixpanelEvent.UpcomingEventActionsClickHelpForOrientation]: ProcessEventData;
    [MixpanelEvent.ProfileStartFieldEdit]: {
        field: string;
    };
    [MixpanelEvent.ProfileFinishFieldEdit]: {
        field: string;
    };
    [MixpanelEvent.ProfileUpdateSwitchValue]: {
        field: string;
        new_value: boolean;
    };
    [MixpanelEvent.LoginSubmitPhoneNumber]: {
        phone: string;
    };
    [MixpanelEvent.RegistrationGetSubmitError]: {
        phone: string;
        error: string;
        type: string | undefined;
    };
}

type MixpanelEventWithData = {
    [T in MixpanelEvent]: T extends keyof AllMixpanelEventsData
        ? [T, AllMixpanelEventsData[T]]
        : [T];
}[MixpanelEvent];

const TECHNICAL_EVENTS = new Set([
    MixpanelEvent.WindowResize,
    MixpanelEvent.AttachedRecaptchaToDom,
]);

interface DefaultProperties {
    is_technical_event?: true;
}

function getDefulatProperties(event: MixpanelEvent) {
    const properties: DefaultProperties = {};
    if (TECHNICAL_EVENTS.has(event)) {
        properties.is_technical_event = true;
    }
    return properties;
}

function parseEventName([event, data]: MixpanelEventWithData): string {
    if (event === MixpanelEvent.VisitPage) {
        return `${event} ${data.page}`;
    }
    return event;
}

export const trackEvent = (...args: MixpanelEventWithData) => {
    if (!isMixpanelOn() && !sendEventToLog()) {
        return;
    }
    const [event, data] = args;
    const properties = { ...getDefulatProperties(event), ...data };
    const parsedProperties = mapValues(properties, (prop) =>
        typeof prop === 'object' ? JSON.stringify(prop) : prop,
    );
    const parsedName = parseEventName(args);
    if (sendEventToLog()) {
        console.log('[track_mixpanel_event]', parsedName, parsedProperties);
    }
    if (isMixpanelOn()) {
        mixpanel.track(parsedName, parsedProperties);
    }
};
