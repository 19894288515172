import Loader from '@/components/lotties/Loader';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import useScreeningScheduleLink from '@/fetchers/useScreeningScheduleLink';
import useGoTo from '@/hooks/useGoTo';
import {
    ScheduleIntroCallView,
    ScreeningFlowState,
    useRegisterScheduledEvent,
    useScreeningState,
} from '@/introCall';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { introCallPage as strings } from '@/strings';
import { getFirstName } from '@/strings/common';
import { useEffect, useState } from 'react';

import AppPageLayout from '../AppPageLayout';

enum PageState {
    NoScreeningNeed = 'noScreeningNeed',
    Loading = 'loading',
    Ready = 'ready',
}

function getPageState({
    screeningState,
    isLinkReady,
    hasLink,
}: {
    screeningState: ScreeningFlowState;
    isLinkReady: boolean;
    hasLink: boolean;
}): PageState {
    if (!isLinkReady) {
        return PageState.Loading;
    }
    if (!hasLink) {
        return PageState.NoScreeningNeed;
    }
    if (screeningState === ScreeningFlowState.WaitingData) {
        return PageState.Loading;
    }
    if (screeningState === ScreeningFlowState.Pending) {
        return PageState.Ready;
    }
    return PageState.NoScreeningNeed;
}

function ScheduleIntroCallPageLayout({ skippable }: { skippable: boolean }) {
    const { goToHome } = useGoTo();
    const { registerEvent } = useRegisterScheduledEvent();
    const { data: candidate } = useCurrentCandidate();
    const screeningState = useScreeningState();
    const { data: link, isReady: isLinkReady } = useScreeningScheduleLink();
    const [isCalendlyWidgetOpen, setIsCalendlyWidgetOpen] = useState(false);
    const [wasEverReady, setWasEverReady] = useState(false);

    const pageState = getPageState({
        screeningState,
        isLinkReady,
        hasLink: !!link,
    });

    useEffect(() => {
        if (pageState !== PageState.Loading) {
            trackEvent(MixpanelEvent.GotIntroCallPageState, { page_state: pageState });
        }
    }, [pageState]);

    useEffect(() => {
        if (pageState === PageState.Ready) {
            setWasEverReady(true);
        } else if (
            pageState === PageState.NoScreeningNeed &&
            (!wasEverReady || !isCalendlyWidgetOpen)
        ) {
            goToHome();
        }
    }, [pageState, isCalendlyWidgetOpen, wasEverReady, goToHome]);

    return (
        <AppPageLayout>
            <div className={cn('w-full h-full flex justify-center items-center p-4')}>
                {pageState === PageState.Loading && (
                    <div
                        className={cn(
                            'flex justify-center items-center py-6 h-full w-full',
                        )}
                    >
                        <Loader variant='primary' sizing='lg' delay={700} />
                    </div>
                )}
                {wasEverReady && (
                    <div
                        className={cn(
                            'w-full h-full flex flex-col items-center justify-between py-2',
                        )}
                    >
                        <div />
                        <div className={cn('max-w-[550px]')}>
                            <ScheduleIntroCallView
                                customTitle={strings.title.func(
                                    getFirstName(candidate?.name ?? ''),
                                )}
                                customSubtitle={strings.subtitle}
                                registerEvent={registerEvent}
                                inProgressCandidateMsg={false}
                                open={isCalendlyWidgetOpen}
                                setOpen={setIsCalendlyWidgetOpen}
                            />
                        </div>
                        {skippable ? (
                            <div className='flex justify-center'>
                                <div
                                    onClick={() => {
                                        trackEvent(
                                            MixpanelEvent.IntroCallPageClickSkipButton,
                                        );
                                        goToHome();
                                    }}
                                    className={cn(
                                        'inline text-13 text-neutral300 cursor-pointer',
                                    )}
                                >
                                    {strings.skipButton}
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                )}
            </div>
        </AppPageLayout>
    );
}

export default ScheduleIntroCallPageLayout;
