import { ButtonProps, LoadingButtonProps } from '@/components/ui';
import { CandidateCareSetting, CandidateJobType, CandidateShift } from '@/lib/api/v1';
import { DateIsoString, SimpleFuncOrNull } from '@/lib/base';
import {
    OnboardingAnswerData,
    OnboardingFormStep,
    OnboardingQuestion,
} from '@/lib/types';
import { ReactNode } from 'react';

import { utmParams } from './utmParams';

export enum FormAdditionalDataKey {
    UtmData = 'utmData',
}

export type UtmData = {
    [key in (typeof utmParams)[number]]?: string;
};

export interface FormAdditionalDataValue {
    [FormAdditionalDataKey.UtmData]: UtmData;
}

export type FormDataKey = OnboardingQuestion | FormAdditionalDataKey;

export type FormDataValue<K extends FormDataKey> = K extends OnboardingQuestion
    ? OnboardingAnswerData[K]
    : K extends FormAdditionalDataKey
      ? FormAdditionalDataValue[K]
      : never;

export interface RegistrationFooterProps {
    disabled?: boolean;
    verifier?: () => boolean;
    sideEffect?: () => void;
    additionalButtons?: ReactNode;
    nextButtonProps?: ButtonProps | Partial<LoadingButtonProps>;
}

export interface RegistrationStepLayoutProps
    extends Omit<RegistrationFooterProps, 'disabled'> {
    canGoNext?: boolean;
    hideFooter?: boolean;
    children: ReactNode;
}

export enum ShouldRedirectToHomepage {
    Yes = 'yes',
    WaitingForData = 'waitingForData',
    No = 'no',
}

export interface AdditionalAttribiutes {
    hasDrivingLicense?: boolean | null;
    hasCar?: boolean | null;
}

export interface NonAuthRegisteredUser {
    phone: string;
    isReregistration: boolean;
    token?: {
        value: string;
        exp: DateIsoString;
    };
}

export interface RegisterFormState {
    stepIndex: number;
    currentStep: OnboardingFormStep;
    currentStepIndex: number;
    totalSteps: number;
    longTermInterest: boolean | null;
    setLongTermInterest: (v: boolean | null) => void;
    hasCar: boolean | null;
    setHasCar: (v: boolean | null) => void;
    hasDrivingLicense: boolean | null;
    setHasDrivingLicense: (v: boolean | null) => void;
    profession: string;
    setProfession: (prof: string) => void;
    zipcode: string;
    setZipcode: (zc: string) => void;
    zipcodeLocation: string;
    setZipcodeLocation: (zcl: string) => void;
    fullname: string;
    setFullname: (name: string) => void;
    email: string;
    setEmail: (email: string) => void;
    phoneNumber: string;
    setPhoneNumber: (setPhoneNumber: string) => void;
    salary: number | null;
    setSalary: (salary: number | null) => void;
    shifts: CandidateShift[];
    toggleShift: (shift: CandidateShift) => void;
    careSettings: CandidateCareSetting[];
    toggleCareSetting: (cs: CandidateCareSetting) => void;
    jobTypes: CandidateJobType[];
    toggleJobType: (jt: CandidateJobType) => void;
    yearsExp: number | null;
    setYearsExp: (yearsExp: number | null) => void;
    recentJobCompany: string;
    setRecentJobCompany: (value: string) => void;
    recentJobTitle: string;
    setRecentJobTitle: (value: string) => void;
    recentJobStart: string;
    setRecentJobStart: (value: string) => void;
    recentJobEnd: string;
    setRecentJobEnd: (value: string) => void;
    recentJobIsCurrent: boolean;
    setRecentJobIsCurrent: (value: boolean) => void;
    cvFile: File | null;
    setCvFile: (cvFile: File | null) => void;
    preAuthUser: NonAuthRegisteredUser | null;
    setPreAuthUser: (user: NonAuthRegisteredUser | null) => void;
    sendPhoneCode: (phone: string) => Promise<void>;
    submitConfirmationCode: (code: string) => Promise<void>;
    hasSentConfirmationCode: boolean;
    utmData: UtmData;
    preventGoBack: boolean;
    setPreventGoBack: (v: boolean) => void;
    defaultGoNext: () => void;
    defaultGoBack: () => void;
    customGoNextAction: SimpleFuncOrNull;
    setCustomGoNextAction: (fn: SimpleFuncOrNull) => void;
    customGoBackAction: SimpleFuncOrNull;
    setCustomGoBackAction: (fn: SimpleFuncOrNull) => void;
}
