import Logger from '@/_helpers/logger';
import { getDateOrNull } from '@/lib/base';
import { localStorageGraceful, sessionStorageGraceful } from '@/lib/utils';
import { isEmpty } from 'lodash-es';

import { NonAuthRegisteredUser } from './types';

export const MAIN_KEY = 'carefam_register_form';
export const SESSION_STORED_USER_KEY = 'carefam_preauth_user';

export const getStoredState = () => {
    if (!localStorageGraceful) {
        return {};
    }
    const storedValue = localStorageGraceful.getItem(MAIN_KEY);
    const _parseStoredValue = () => {
        if (!storedValue) {
            return {};
        }
        try {
            const parsedState = JSON.parse(storedValue);
            if (typeof parsedState !== 'object') {
                return {};
            }
            return parsedState;
        } catch (e) {
            Logger.error(`Error parsing stored state ${e}`);
            return {};
        }
    };

    const storedState = _parseStoredValue();
    if (isEmpty(storedState) && storedValue !== '{}') {
        if (storedValue !== null) {
            Logger.warn(`reseting old stored state value: "${storedValue}"`);
        }
        localStorageGraceful.setItem(MAIN_KEY, '{}');
    }
    return storedState;
};

export const getStoredPreAuthUser = (): NonAuthRegisteredUser | null => {
    if (!sessionStorageGraceful) {
        return null;
    }
    const storedValue = sessionStorageGraceful.getItem(SESSION_STORED_USER_KEY);
    if (!storedValue) {
        return null;
    }
    try {
        const parsedValue = JSON.parse(storedValue);
        const user: NonAuthRegisteredUser = {
            phone: parsedValue.phone,
            isReregistration: parsedValue.isReregistration,
            token: parsedValue.token,
        };
        const { phone, isReregistration, token } = user;
        if (typeof phone !== 'string' || typeof isReregistration !== 'boolean') {
            return null;
        }
        if (!isReregistration) {
            if (typeof token !== 'object') {
                return null;
            }
            const { value, exp } = token;
            if (typeof value !== 'string' || typeof exp !== 'string') {
                return null;
            }
            const expDate = getDateOrNull(exp);
            if (!expDate || expDate < new Date()) {
                return null;
            }
        }
        return user;
    } catch (e) {
        Logger.error(`Error parsing stored pre_auth_user ${e}`);
        return null;
    }
};
