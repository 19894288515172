export enum QueryParam {
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_TERM = 'utm_term',
    UTM_CONTENT = 'utm_content',
    UTM_ID = 'utm_id',
    AD_ID = 'ad_id',
    ADSET_ID = 'adset_id',
    PLACEMENT = 'placement',
    SITE_SOURCE_NAME = 'site_source_name',
    SIGN_IN_PHONE_NUMBER = 'phonenumber',
    PROCESS_ID = 'process_id',
}

export enum StatusPageTab {
    CurrentProcesses = 'current-processes',
    UpcomingEvents = 'upcoming-events',
}
