import { DayOfWeek } from '@/lib/api/v1';
import { TZ, TimeInterval } from '@/lib/types';
import { format, getDay, parse, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function dayStringToLocalDate(day: string): Date {
    return parseISO(day);
}

export function dateObjToDateString(date: Date): string {
    return format(date, 'yyyy-MM-dd');
}

export function isoStringToDayString(isoString: string): string {
    return isoString.split('T')[0];
}

export function getLocalDayFromIsoString(isoString: string): string {
    return dateTimeToDayString(new Date(isoString));
}

export function dateTimeToDayString(date: Date): string {
    return format(date, 'yyyy-MM-dd');
}

export function hourNumToString(hs: number): string {
    const parsedTime = parse(`${hs}`, 'HH', new Date());
    return format(parsedTime, 'hh:mm aa');
}

export function hourAndMinuteToString(hs: number, ms: number): string {
    const parsedTime = parse(`${hs}:${ms}`, 'HH:mm', new Date());
    return format(parsedTime, 'hh:mm aa');
}

export function sortIntervals(a: TimeInterval, b: TimeInterval): number {
    return a.start.valueOf() - b.start.valueOf();
}

export function getPythonWeekday(date: Date): DayOfWeek {
    const jsDay = getDay(date); // Returns 0 (Sunday) to 6 (Saturday)
    return ((jsDay + 6) % 7) as DayOfWeek; // Adjust to make Monday = 0, Sunday = 6
}

export function isWithinTimeframe({
    startWeekDay,
    endWeekDay,
    startWorkingHour,
    endWorkingHour,
    timezone,
}: {
    startWeekDay: number;
    endWeekDay: number;
    startWorkingHour: number;
    endWorkingHour: number;
    timezone: TZ;
}): boolean {
    const now = new Date();
    const zonedNow = utcToZonedTime(now, timezone);
    const currentDay = getPythonWeekday(zonedNow);
    const currentHour = zonedNow.getHours();
    if (currentDay < startWeekDay || currentDay > endWeekDay) {
        return false;
    }
    if (currentHour < startWorkingHour || currentHour >= endWorkingHour) {
        return false;
    }
    return true;
}
