import { useCallback } from 'react';

import { sendAdsCampaignEventOnce } from '.';
import { useUtmSource } from './UtmSourceContainer';
import { KNOWN_UTM_SOURCE_KEYWORDS_MAP } from './consts';
import { CampaignEventName } from './types';

function useTrackAdsCampaignEvent() {
    const utmSource = useUtmSource();
    const knownUtmSource = KNOWN_UTM_SOURCE_KEYWORDS_MAP[utmSource];
    const trackEvent = useCallback(
        (
            eventName: CampaignEventName,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            eventData?: Record<string, any>,
        ) => sendAdsCampaignEventOnce(knownUtmSource, eventName, eventData),
        [knownUtmSource],
    );
    return trackEvent;
}
export default useTrackAdsCampaignEvent;
