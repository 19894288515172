import { useAppDispatch } from '@/_helpers/store';
import { openDialogDismissOthers, setDialogOpen } from '@/_reducers/dialogs';
import { cancelInterviewEvent } from '@/_reducers/processes';
import { CancelInterviewDialogData } from '@/_selectors';
import { IsCancelInterviewDialogOpen } from '@/_selectors/generation_1/dialogs';
import { DialogName, EventRelatedProcess, TerminationDialogVariant } from '@/lib/types';
import { useSelector } from 'react-redux';

import CancelInterviewDialog from './CancelInterviewDialog';

function CancelInterviewDialogProvider() {
    const dispatch = useAppDispatch();
    const shouldBeOpen = useSelector(IsCancelInterviewDialogOpen);
    const { process } = useSelector(CancelInterviewDialogData);
    const hasData = !!process && !!process.relevantEvent;

    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        dispatch(setDialogOpen(DialogName.CancelInterview, open));
    };
    const handleClose = () => setOpen(false);

    const cancelEvent = async (reason?: string) => {
        if (!hasData || !process.relevantEvent) {
            return;
        }
        return await dispatch(
            cancelInterviewEvent({
                eventId: process.relevantEvent.id,
                reason,
            }),
        );
    };

    const goToTermination = () => {
        if (!hasData) {
            return;
        }
        dispatch(
            openDialogDismissOthers(DialogName.TerminateProcess, {
                processId: process.id,
                variant: TerminationDialogVariant.Termination,
            }),
        );
    };

    return (
        <>
            {hasData && (
                <CancelInterviewDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process as EventRelatedProcess}
                    cancelEvent={cancelEvent}
                    goToTermination={goToTermination}
                />
            )}
        </>
    );
}

export default CancelInterviewDialogProvider;
