import { AppDispatch, RootState } from '@/_helpers/store';
import apiClient from '@/lib/api';
import { FetchModelStatus, FetcherState, Organization } from '@/lib/types';
import { extractErrorMsg, generateEmptyFetcherState } from '@/lib/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';

export const fetchOrganizations = createAsyncThunk<
    Organization[],
    { ids: string[] },
    { state: RootState }
>('organizations/fetchOrganizations', async ({ ids }, { rejectWithValue }) => {
    try {
        const response = await apiClient.GET('/api/v1/organizations', {
            params: {
                query: {
                    ids,
                },
            },
        });
        return response.data!.items;
    } catch (error) {
        return rejectWithValue(extractErrorMsg(error));
    }
});

export const resetOrganizations = () => (dispatch: AppDispatch) => {
    dispatch(organizationsSlice.actions.reset());
};

interface SliceState {
    data: Record<string, Organization>;
    fetcher: FetcherState;
}

const initialState: SliceState = {
    data: {},
    fetcher: generateEmptyFetcherState(),
};

const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizations.pending, (state) => {
                state.fetcher.status = FetchModelStatus.LOADING;
            })
            .addCase(fetchOrganizations.fulfilled, (state, action) => {
                state.fetcher.status = FetchModelStatus.SUCCESS;
                state.fetcher.initiated = true;
                if (action.payload) {
                    state.data = {
                        ...state.data,
                        ...keyBy(action.payload, 'id'),
                    };
                }
            })
            .addCase(fetchOrganizations.rejected, (state, action) => {
                state.fetcher.status = FetchModelStatus.FAILURE;
                state.fetcher.error = action.error.message;
            });
    },
});

export default organizationsSlice;
