import {
    CandidateProfileStatus,
    EventStatus,
    EventType,
    ProcessStatus,
    ProcessSubstatus,
    RecruiterApprovalStatus,
    ResumeStatus,
} from '@/lib/api/v1';
import { DateIsoString } from '@/lib/base';
import { FlatCandidateEvent, Process, Profession } from '@/lib/types';

export const exampleFlatProcessEvent: FlatCandidateEvent = {
    id: '1',
    candidateId: '1',
    allDay: false,
    startAt: '2024-03-08T12:00:00Z' as DateIsoString,
    endAt: '2024-03-08T13:00:00Z' as DateIsoString,
    canceled: false,
    status: EventStatus.Scheduled,
    eventType: EventType.PhoneInterview,
    processId: '1',
};

export const exampleProcess: Process = {
    id: '1',
    candidateId: '1',
    organizationId: '1',
    batchId: '1',
    candidateInterested: true,
    recruiterApproved: true,
    recruiterApprovalStatus: RecruiterApprovalStatus.Approved,
    status: ProcessStatus.PhoneInterview,
    substatus: ProcessSubstatus.Done,
    candidate: {
        id: '1',
        name: 'Candy Deight',
        email: 'Candy@deight.org',
        phone: '+1234567890',
        profession: Profession.RN,
        hourlySalaryFlexible: false,
        createdAt: '2024-03-08T12:00:00Z' as DateIsoString,
        hasResume: true,
        resumeStatus: ResumeStatus.Ready,
        address: 'Address 24 st. at City, MA',
        profileStatus: CandidateProfileStatus.BatchReady,
    },
    recruiter: {} as Process['recruiter'],
    organization: {} as Process['organization'],
    jobMatches: [],
    actions: [],
    statusUpdatedAt: '2024-03-08T12:00:00Z' as DateIsoString,
    createdAt: '2024-03-08T12:00:00Z' as DateIsoString,
    updatedAt: '2024-03-08T12:00:00Z' as DateIsoString,
    relevantEvent: exampleFlatProcessEvent,
    minCommuteDuration: null,
};
