import { MedalIcon } from '@/components/svgs';
import { Button } from '@/components/ui';
import useOpenDialog from '@/dialogs/useOpenDialog';
import { DefaultDivProps } from '@/lib/base';
import { DialogName } from '@/lib/types';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as strings } from '@/strings';

function CompeteYourProfileBlock({ rootProps }: { rootProps?: DefaultDivProps }) {
    const { openDialog } = useOpenDialog(DialogName.UpdateWorkHistory);
    return (
        <div {...rootProps} className={cn('p-8 pt-0', rootProps?.className)}>
            <div className={cn('max-w-[450px] mx-auto')}>
                <div className={cn('text-18 font-bold')}>
                    {strings.completeYourProfile.sectionTitle}
                </div>
                <div
                    className={cn('bg-white rounded-2xl p-8 mt-6 shadow-elevation-300')}
                >
                    <MedalIcon className={cn('h-14')} />
                    <div className={cn('text-24 font-bold mt-6')}>
                        {strings.completeYourProfile.title}
                    </div>
                    <div className={cn('text-13 font-medium mt-2')}>
                        {strings.completeYourProfile.subtitle}
                    </div>
                    <Button
                        variant={'default'}
                        size={'md'}
                        className={cn('w-full mt-8')}
                        onClick={() => {
                            trackEvent(MixpanelEvent.OpenDialogCompleteYourProfile);
                            openDialog(null);
                        }}
                    >
                        {strings.completeYourProfile.updateButton}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default CompeteYourProfileBlock;
