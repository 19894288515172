import { genericToasts } from '../common';

const strings = {
    uploadResume: {
        failureTitle: genericToasts.failure.failureTitle,
        buttonLabel: 'Upload resume',
        errors: {
            aboveSizeLimit: {
                func: (maxSizeInMB: number) =>
                    `File size exceeds limit ${maxSizeInMB}MB`,
                example: [30],
            },
            uploadError: 'Failed to upload resume',
        },
    },
    lastExperience: {
        companyLabel: 'Company name',
        companyPlaceholder: 'Enter',
        roleLabel: 'Title',
        rolePlaceholder: 'Enter',
        startDateLabel: 'Start date',
        endDateLabel: 'End date',
        isCurrentJobLabel: 'I currently work here',
    },
};

export default strings;
