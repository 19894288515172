import { PHONE_NUMBER_LENGTH, PhoneNumberInput } from '@/components/ui';
import { PixelEventName, trackFbPixelEventOnce } from '@/facebookPixel';
import { ApiErrorType } from '@/lib/types';
import {
    cn,
    extractErrorMsg,
    extractErrorType,
    formattedPhoneToInternationalNumber,
    getFirebaseSendPhoneCodeErrorMessage,
    stringToDigitsOnly,
} from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import useActionWithRecaptchaObserver from '@/phoneSignIn/recaptcha/useActionWithRecaptchaObserver';
import { authErrors as authErrorStrings, registration as strings } from '@/strings';
import { useCallback, useEffect, useState } from 'react';

import { useRegisterFormContext } from '../../RegisterContext';
import RegistrationStepLayout from '../../RegistrationStepLayout';
import StepTitle from '../../StepTitle';

function PhoneNumberForm({
    sendPhoneCode,
    phone,
    setPhone,
    register,
}: {
    phone: string;
    setPhone: (phone: string) => void;
    sendPhoneCode: (phone: string) => Promise<void>;
    register: () => Promise<void>;
}) {
    const { setCustomGoNextAction, preAuthUser, setPreAuthUser } =
        useRegisterFormContext();
    const [error, setError] = useState<{ msg: string; type?: string }>({ msg: '' });
    const updateErrorMsg = (errorMsg: string) => {
        setError({ msg: errorMsg, type: undefined });
    };

    const phoneNumber = stringToDigitsOnly(phone);
    const formattedPhoneNumber = formattedPhoneToInternationalNumber(phone);

    const onSubmit = useCallback(async () => {
        trackEvent(MixpanelEvent.RegistrationSubmitPhone, {
            phone: formattedPhoneNumber,
        });
        trackFbPixelEventOnce(PixelEventName.SubmittedPhoneNumber);
        try {
            setError({ msg: '' });
            if (formattedPhoneNumber !== preAuthUser?.phone) {
                try {
                    await register();
                } catch (e) {
                    if (extractErrorType(e) === ApiErrorType.PHONE_NUMBER_TAKEN) {
                        trackEvent(MixpanelEvent.RegistrationSubmitExistingPhoneNumber);
                        setPreAuthUser({
                            phone: formattedPhoneNumber,
                            isReregistration: true,
                        });
                    } else {
                        throw e;
                    }
                }
            }
            await sendPhoneCode(formattedPhoneNumber);
        } catch (e) {
            const type = extractErrorType(e);
            let msg: string;
            if (type === ApiErrorType.INVALID_PHONE_NUMBER) {
                msg = authErrorStrings.invalidPhone;
            } else {
                msg = getFirebaseSendPhoneCodeErrorMessage(e) ?? extractErrorMsg(e);
            }
            trackEvent(MixpanelEvent.RegistrationGetSubmitError, {
                phone: formattedPhoneNumber,
                error: msg,
                type,
            });
            setError({ msg, type });
        }
    }, [sendPhoneCode, formattedPhoneNumber, preAuthUser, register, setPreAuthUser]);

    const { action: submitAndTrackRecaptcha, isLoading } =
        useActionWithRecaptchaObserver(onSubmit);

    useEffect(() => {
        setCustomGoNextAction(submitAndTrackRecaptcha);
        return () => {
            setCustomGoNextAction(null);
        };
    }, [submitAndTrackRecaptcha, setCustomGoNextAction]);

    const canGoNext = phoneNumber.length == PHONE_NUMBER_LENGTH;

    return (
        <RegistrationStepLayout
            canGoNext={canGoNext}
            nextButtonProps={{
                loading: isLoading,
            }}
        >
            <div className={cn('w-full h-full flex flex-col items-center')}>
                <div className={cn('flex flex-col gap-8')}>
                    <StepTitle
                        title={strings.phone.title}
                        subtitle={strings.phone.subtitle}
                    />
                    <div className={cn('w-full max-w-[350px]')}>
                        <PhoneNumberInput
                            value={phone}
                            setValue={setPhone}
                            error={error.msg}
                            setError={updateErrorMsg}
                        />
                    </div>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default PhoneNumberForm;
