import Logger from '@/_helpers/logger';
import { format } from 'date-fns';

import { DateIsoString, DayString, NumberString, TimeString } from './types';

export function getAllEnumKeys<T extends object>(enumObj: T) {
    return Object.keys(enumObj)
        .filter((key) => {
            return isNaN(Number(key));
        })
        .map((key) => key as keyof T);
}

export function getAllEnumValues<T extends object>(enumObj: T): T[keyof T][] {
    return getAllEnumKeys(enumObj).map((key) => enumObj[key]);
}

export const sortEnumList = <T>(list: T[], orderMap: T[]): T[] => {
    return [...list].sort((a, b) => {
        const indexA = orderMap.indexOf(a);
        const indexB = orderMap.indexOf(b);
        return indexA - indexB;
    });
};

export function ISOtoDate(value: DateIsoString) {
    return new Date(value);
}

export function toISOString(date: Date) {
    return date.toISOString() as DateIsoString;
}

export function toDayString(date: Date) {
    return format(date, 'yyyy-MM-dd') as DayString;
}
export function isoDatetoLocalDayString(date: DateIsoString) {
    return toDayString(new Date(date));
}

export function toTimeString(date: Date) {
    return format(date, 'HH:mm') as TimeString;
}
export function isoDatetoLocalTimeString(date: DateIsoString) {
    return toTimeString(new Date(date));
}

export function dayStringToIsoString(dayString: DayString) {
    return `${dayString}T00:00:00.000Z` as DateIsoString;
}

export function compareArrays<T>(array1: T[], array2: T[]) {
    return JSON.stringify(array1) === JSON.stringify(array2);
}

export function toNumberOrNone(value: NumberString): number | undefined {
    if (value === '') {
        return undefined;
    }
    return Number(value);
}

export function getDateOrNull(value: string) {
    if (!value) {
        return null;
    }
    const date = new Date(value);
    if (isNaN(Number(date))) {
        return null;
    }
    return date;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractMsgFromCatchedError(error: any, withStackTrace = false): string {
    if (error instanceof Error) {
        let res = `${error.name}: ${error.message}`;
        if (withStackTrace) {
            res += `\n${error.stack || 'No stack trace available'}`;
        }
        return res;
    }
    if (typeof error === 'object') {
        try {
            return JSON.stringify(error);
        } catch {
            Logger.error(
                error,
                'Could not stringify error object due to circular references',
            );
            return 'Unknown Error';
        }
    }
    return String(error);
}
