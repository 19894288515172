import { genericToasts } from '../common';

const strings = {
    search: {
        title: {
            func: (firstName: string) => `Welcome ${firstName}!`,
            example: ['Candy'],
        },
        subtitle: "We're searching facilities near you that match you job preferences",
    },
    noProcesses: {
        title: "We couldn't find a matching facility",
        subtitle:
            "No matching facilities right now. We'll notify you when relevant positions open up.",
    },
    noMoreProcesses: {
        title: 'No facilities match your criteria right now.',
        subtitle: "We'll keep looking and notify you when something relevant opens up.",
    },
    scheduleIntroCall: {
        title: 'Start your job search',
        subtitle: {
            newCandidate:
                "Next, set up an intro call with a Carefam representative. This isn't an interview, so no prep is needed 🙂",
            inProgressCandidate:
                "Next, set up an intro call with a Carefam representative, to activate your job search and find a new role in under 10 days! This isn't an interview, so no prep is needed 🙂",
        },
        button: 'Schedule a call',
        callUsNow: {
            button: 'Call us now',
        },
    },
    screeningEvent: {
        title: 'Carefam intro call',
        actions: {
            reschedule: 'Reschedule your call',
            cancel: 'Cancel your call',
        },
        cancelDialg: {
            title: 'Confirm you want to cancel this event',
            subtitle: 'Reason for canceling:',
            submit: 'Confirm',
            close: 'Go back',
            failureToast: { ...genericToasts.failure },
        },
    },
    postScreening: {
        title: 'Your next interview is coming up soon',
        subtitle:
            "Interview invitations will start coming in soon. We'll keep you posted by SMS.",
    },
    completeYourProfile: {
        sectionTitle: 'Complete your profile',
        title: 'Update your work history',
        subtitle:
            'In order to move forward with hiring processes and present you to recruiters, we need information about your recent workplaces.',
        updateButton: 'Update now',
    },
};

export default strings;
