import { fetchCurrentCandidate } from '@/_reducers/candidate';
import { NoOpReduxAction } from '@/_reducers/utils';
import { CurrentCandidate, CurrentCandidateFetcherData, IsLoggedIn } from '@/_selectors';
import { useSelector } from 'react-redux';

import useFetcherBase from './useFetcherBase';

function useCurrentCandidate() {
    const isLoggedIn = useSelector(IsLoggedIn);
    const fetchFunc = isLoggedIn ? fetchCurrentCandidate : NoOpReduxAction;

    return useFetcherBase({
        fetchFunc: fetchFunc,
        dataSelector: CurrentCandidate,
        fetcherSelector: CurrentCandidateFetcherData,
    });
}

export default useCurrentCandidate;
