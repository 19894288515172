function hexToRgb(hex: string) {
    hex = hex.replace(/^#/, '');

    if (hex.length === 3) {
        hex = hex
            .split('')
            .map((char) => char + char)
            .join('');
    }

    if (hex.length !== 6) return null;

    const bigint = parseInt(hex, 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
    };
}

function parseRgbString(rgbStr: string) {
    const regex =
        /rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})(?:\s*,\s*[\d.]+)?\)/i;
    const result = regex.exec(rgbStr);
    if (!result) return null;

    return {
        r: parseInt(result[1], 10),
        g: parseInt(result[2], 10),
        b: parseInt(result[3], 10),
    };
}
export function colorStringToRgb(color: string) {
    if (typeof color !== 'string') return null;

    color = color.trim().toLowerCase();

    if (color.startsWith('#')) {
        return hexToRgb(color);
    } else {
        return parseRgbString(color);
    }
}

export function calculateBrightness(r: number, g: number, b: number) {
    // YIQ formula to determine brightness   # https://en.wikipedia.org/wiki/YIQ
    return (r * 299 + g * 587 + b * 114) / 1000;
}
