import { getAllEnumValues } from '@/lib/base';
import {
    OnboardingFormStep,
    OnboardingQuestion,
    OnboardingQuestionWithAnswer,
    Profession,
} from '@/lib/types';
import { AllMixpanelEventsData, MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { debounce } from 'lodash-es';

import { formStepsOrderd } from './steps';
import {
    AdditionalAttribiutes,
    FormDataKey,
    NonAuthRegisteredUser,
    RegisterFormState,
} from './types';

export function isOnboardingQuestion(key: FormDataKey): key is OnboardingQuestion {
    return getAllEnumValues(OnboardingQuestion).includes(key as OnboardingQuestion);
}

export function getOrderedFormSteps(profession: string) {
    let res = [...formStepsOrderd];
    if (profession !== Profession.HHA) {
        res = res.filter((s) => s !== OnboardingFormStep.AdditionalAttrs);
    }
    return res;
}

export function getAdditionalAttributes({
    profession,
    hasCar,
    hasDrivingLicense,
}: Pick<
    RegisterFormState,
    'profession' | 'hasCar' | 'hasDrivingLicense'
>): AdditionalAttribiutes {
    if (profession !== Profession.HHA) {
        return {};
    }
    return { hasCar, hasDrivingLicense };
}

const debouncedTrackUpdateAnswerEventGetter = (() => {
    let debouncedFunc: (
        ...args: [AllMixpanelEventsData[MixpanelEvent.RegistrationUpdateAnswer]]
    ) => void;
    let lastKey: OnboardingQuestion;
    return {
        get(key: OnboardingQuestion) {
            if (lastKey !== key || !debouncedFunc) {
                lastKey = key;
                debouncedFunc = debounce(
                    (
                        ...args: [
                            AllMixpanelEventsData[MixpanelEvent.RegistrationUpdateAnswer],
                        ]
                    ) => trackEvent(MixpanelEvent.RegistrationUpdateAnswer, ...args),
                    1500,
                );
            }
            return debouncedFunc;
        },
    };
})();

export const debouncedTrackUpdateAnswerEvent = (
    payload: AllMixpanelEventsData[MixpanelEvent.RegistrationUpdateAnswer],
) => {
    const debouncedTrackFunc = debouncedTrackUpdateAnswerEventGetter.get(
        payload.question,
    );
    debouncedTrackFunc(payload);
};

export function shouldSkipTrackEvent({
    question,
    answer,
}: OnboardingQuestionWithAnswer) {
    if (
        [OnboardingQuestion.RecentJobStart, OnboardingQuestion.RecentJobEnd].includes(
            question,
        ) &&
        !answer
    ) {
        return true;
    }
    return false;
}

export function canOnlyUpdatePhoneNumber(preAuthUser: NonAuthRegisteredUser): boolean {
    const tokenExp = preAuthUser.token?.exp;
    if (tokenExp && new Date(tokenExp) > new Date()) {
        return true;
    }
    return false;
}
