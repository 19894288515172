import { DIALOG_ANIMATION_LENGTH } from '@/_reducers/dialogs';
import { TerminationLottie } from '@/components/lotties';
import { Dialog, DialogHeaderAnchor, useToast } from '@/components/ui';
import { Process, TerminationDialogVariant } from '@/lib/types';
import { cn, extractErrorMsg } from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { dialogs as dialogsStrings } from '@/strings';
import { useEffect, useState } from 'react';

import SuccessContent from '../common/SuccessContent';
import DialogTitleHeader, { SUBTITLE_TEXT_CLS } from '../common/header';
import { BaseDialogPropsCommon } from '../types';
import TerminateDialogConfirmationContent from './confirmationContent';
import { TerminateProcessDialogContent } from './content';
import { SelectedTerminationReason, TerminateFunc } from './types';

const strings = dialogsStrings.termination;

interface Props extends BaseDialogPropsCommon {
    process: Process;
    terminate: TerminateFunc;
    variant: TerminationDialogVariant;
}

enum Step {
    Form = 'form',
    Confirmation = 'confirm',
    Terminated = 'terminated',
}

function getVariantStrings(variant: TerminationDialogVariant) {
    if (variant === TerminationDialogVariant.DeclineInterview) {
        return strings.declineInterview;
    }
    return strings;
}

function TerminateProcessDialog({
    process,
    variant,
    terminate,
    isOpen,
    setOpen,
    handleClose,
}: Props) {
    const { toast } = useToast();
    const [step, setStep] = useState<Step>(Step.Form);
    const [reason, setReason] = useState<SelectedTerminationReason>('');
    const [freeText, setFreeText] = useState('');

    const goToConfirmation = () => setStep(Step.Confirmation);
    const submitTermination = async () => {
        if (!reason) return;
        await terminate({
            reason,
            additionalText: freeText,
        })
            .then(() => {
                setStep(Step.Terminated);
            })
            .catch((error) => {
                toast({
                    title: strings.failureTitle,
                    description: extractErrorMsg(error),
                    variant: 'destructive',
                });
            });
    };

    const reset = () => {
        setStep(Step.Form);
        setReason('');
        setFreeText('');
    };

    useEffect(() => {
        if (!isOpen) {
            setTimeout(reset, DIALOG_ANIMATION_LENGTH);
        }
    }, [isOpen, setStep]);

    const variantStrings = getVariantStrings(variant);
    const updateSelectedReason = (newVal: SelectedTerminationReason) => {
        trackEvent(MixpanelEvent.SelectTerminationReason, {
            ...extractProcessDataForMixpanel(process),
            dialog_variant: variant,
            reason: newVal,
        });
        setReason(newVal);
    };

    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            customHeader={
                <>
                    {step === Step.Form && (
                        <DialogTitleHeader
                            title={variantStrings.title}
                            subtitle={variantStrings.subtitle}
                            rootProps={{ className: 'border-none' }}
                            additionalContent={
                                variant === TerminationDialogVariant.DeclineInterview ? (
                                    <div className={cn(SUBTITLE_TEXT_CLS)}>
                                        <span className={cn('underline')}>
                                            {strings.declineInterview.headerNote.label}
                                        </span>
                                        : {strings.declineInterview.headerNote.content}
                                    </div>
                                ) : undefined
                            }
                        />
                    )}
                    {step === Step.Confirmation && <DialogHeaderAnchor />}
                    {step === Step.Terminated && <DialogHeaderAnchor />}
                </>
            }
        >
            <>
                {step === Step.Form && (
                    <TerminateProcessDialogContent
                        submit={() => {
                            trackEvent(MixpanelEvent.SubmitTerminationReason, {
                                ...extractProcessDataForMixpanel(process),
                                reason,
                                dialog_variant: variant,
                            });
                            goToConfirmation();
                        }}
                        reason={reason}
                        setReason={updateSelectedReason}
                        freeText={freeText}
                        setFreeText={setFreeText}
                        submitText={variantStrings.submitButton}
                        reasonPlacehloder={variantStrings.reasonPlacehloder}
                    />
                )}
                {step === Step.Confirmation && (
                    <TerminateDialogConfirmationContent
                        process={process}
                        confirm={async () => {
                            trackEvent(MixpanelEvent.ConfirmProcessTermination, {
                                ...extractProcessDataForMixpanel(process),
                                reason,
                                dialog_variant: variant,
                            });
                            return await submitTermination();
                        }}
                        cancel={() => {
                            trackEvent(MixpanelEvent.CancelProcessTermination);
                            handleClose();
                        }}
                    />
                )}
                {step === Step.Terminated && (
                    <SuccessContent
                        customIcon={
                            <TerminationLottie className={cn('w-[200px] h-fit')} />
                        }
                        title={variantStrings.success.title}
                        subtitle={variantStrings.success.subtitle}
                    />
                )}
            </>
        </Dialog>
    );
}

export default TerminateProcessDialog;
