import { DialogContentWithFooter } from '@/components/ui';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogStrings } from '@/strings';
import LastWorkPlaceForm from '@/workHistory/LastWorkPlaceForm';
import { RecentJob } from '@/workHistory/types';
import { emptyRecentJob } from '@/workHistory/utils';
import { useState } from 'react';

import SuccessContent from '../common/SuccessContent';
import UploadResumeStep from './UploadResumeStep';
import UpdateWorkHistoryDialogFooter from './footer';
import { DialogStep, SubmittableStep } from './types';

const strings = dialogStrings.workHistory;

interface Props {
    candidateId: string;
    step: DialogStep;
    setStep: (step: DialogStep) => void;
}

function UpdateWorkHistoryDialogContent({ candidateId, step, setStep }: Props) {
    const [cvFile, setCvFile] = useState<File | null>(null);
    const [recentJob, setRecentJob] = useState<RecentJob>(emptyRecentJob);
    const [recentJobDateError, setRecentJobDateError] = useState('');
    const [lastSubmittedStep, setLastSubmittedStep] = useState<SubmittableStep>(
        DialogStep.CV,
    );
    const [submitResumeSideEffect, setSubmitResumeSideEffect] = useState<() => void>(
        () => {},
    );

    const { company, role, startDate, endDate, isCurrentJob } = recentJob;

    const setRecentJobParam = <T extends keyof RecentJob>(
        param: T,
        value: RecentJob[T],
    ) => {
        setRecentJob((state) => ({ ...state, [param]: value }));
    };

    const goToSuccess = (step: SubmittableStep) => {
        setLastSubmittedStep(step);
        setStep(DialogStep.Success);
    };
    const goToRecentJob = () => {
        trackEvent(MixpanelEvent.ResumeGoToRecentJobForm);
        setStep(DialogStep.RecentJob);
    };

    return (
        <DialogContentWithFooter
            footer={
                DialogStep.CV === step || DialogStep.RecentJob === step ? (
                    <UpdateWorkHistoryDialogFooter
                        candidateId={candidateId}
                        step={step}
                        onSuccess={goToSuccess}
                        submitResumeSideEffect={submitResumeSideEffect}
                        cvFile={cvFile}
                        recentJob={recentJob}
                        recentJobDateError={recentJobDateError}
                    />
                ) : null
            }
            bodyProps={{ className: cn('p-0') }}
        >
            <div className={cn('w-full flex flex-col max-w-[500px] mx-auto')}>
                {step === DialogStep.CV && (
                    <UploadResumeStep
                        candidateId={candidateId}
                        goToRecentJob={goToRecentJob}
                        cvFile={cvFile}
                        setCvFile={setCvFile}
                        setSubmitResumeSideEffect={(fn: () => void) =>
                            setSubmitResumeSideEffect(() => fn)
                        }
                    />
                )}

                {step === DialogStep.RecentJob && (
                    <div className={cn('flex flex-col p-10 pt-4 pb-14 items-stretch')}>
                        <LastWorkPlaceForm
                            company={company}
                            setCompany={(val) => setRecentJobParam('company', val)}
                            role={role}
                            setRole={(val) => setRecentJobParam('role', val)}
                            startDate={startDate}
                            setStartDate={(val) => setRecentJobParam('startDate', val)}
                            endDate={endDate}
                            setEndDate={(val) => setRecentJobParam('endDate', val)}
                            isCurrentJob={isCurrentJob}
                            setIsCurrentJob={(val) =>
                                setRecentJobParam('isCurrentJob', val)
                            }
                            dateError={recentJobDateError}
                            setDateError={setRecentJobDateError}
                        />
                    </div>
                )}

                {step == DialogStep.Success && (
                    <SuccessContent
                        title={
                            lastSubmittedStep === DialogStep.CV
                                ? strings.success.resumeUploadTitle
                                : strings.success.experienceUploadTitle
                        }
                        subtitle={strings.success.subtitle}
                    />
                )}
            </div>
        </DialogContentWithFooter>
    );
}

export default UpdateWorkHistoryDialogContent;
