import { ReregisteredCandidateData } from '@/_selectors';
import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import { Candidate, OnboardingFormStep } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RegisterFormState } from './types';

function shouldSkipStep(step: OnboardingFormStep, candidate: Candidate) {
    const { hourlySalaryFlexible, hourlySalary, hasResume, experiences } = candidate;
    if (step === OnboardingFormStep.Salary && !hourlySalaryFlexible && hourlySalary) {
        return true;
    }
    if (
        step === OnboardingFormStep.Resume &&
        (hasResume || (experiences ?? []).length > 0)
    ) {
        return true;
    }
    return false;
}

function useSkipPostRegistrationStep({
    currentStep,
    currentStepIndex,
    defaultGoNext,
}: Pick<RegisterFormState, 'currentStep' | 'currentStepIndex' | 'defaultGoNext'>) {
    const reregisteredCandidate = useSelector(ReregisteredCandidateData);
    const { data: currentCandidate } = useCurrentCandidate();
    const [loggedInCandidate, setLoggedInCandidate] = useState<Candidate>();

    const skipCurrentStep = useCallback(() => {
        trackEvent(MixpanelEvent.RegistrationAutoSkipPostRegistrationStep, {
            current_step: currentStep,
            current_step_index: currentStepIndex,
        });
        defaultGoNext();
    }, [defaultGoNext, currentStep, currentStepIndex]);

    useLayoutEffect(() => {
        const candidate = reregisteredCandidate ?? loggedInCandidate;
        if (!candidate) {
            return;
        }
        if (shouldSkipStep(currentStep, candidate)) {
            skipCurrentStep();
        }
    }, [skipCurrentStep, currentStep, reregisteredCandidate, loggedInCandidate]);

    useEffect(() => {
        if (!isEmpty(currentCandidate) && !loggedInCandidate) {
            setLoggedInCandidate(currentCandidate);
        }
    }, [currentCandidate, loggedInCandidate, setLoggedInCandidate]);
}

export default useSkipPostRegistrationStep;
