import { OnboardingFormStep } from '@/lib/types';
import { useEffect } from 'react';

import { NonAuthRegisteredUser } from './types';
import { canOnlyUpdatePhoneNumber } from './utils';

function useEnforcePhoneStepForRegisteredUser({
    steps,
    preAuthUser,
    currentStepIndex,
    defaultGoNext,
}: {
    steps: OnboardingFormStep[];
    preAuthUser: NonAuthRegisteredUser | null;
    currentStepIndex: number;
    defaultGoNext: () => void;
}) {
    const preventPrePhoneSteps = !!preAuthUser && canOnlyUpdatePhoneNumber(preAuthUser);
    const phoneStepIndex = steps.indexOf(OnboardingFormStep.PhoneNumber);

    useEffect(() => {
        if (!preventPrePhoneSteps) {
            return;
        }
        if (currentStepIndex < phoneStepIndex) {
            defaultGoNext();
        }
    }, [currentStepIndex, defaultGoNext, preventPrePhoneSteps, phoneStepIndex]);
}

export default useEnforcePhoneStepForRegisteredUser;
